import React from "react";
import PageWrapper from "../components/PageWrapper";
import Intro from "../components/Intro";
import Latest from "../components/latest";
import BlockImage from "../components/blockImage";
import Button from "../components/button";
import CardBuy from "../components/cardBuy";
import CardsReassurance from "../components/cardsReassurance";

export default function GuidPage() {
  return (
    <div className="page-home">
      <PageWrapper>
        <Intro />
        <CardsReassurance />
        <Latest />
        <BlockImage>
          <h2>
            <span className="small">
              VENDRE UN
              <span className="highlight"> VÉHICULE</span>
            </span>
            Votre voiture ne s’est <br />
            jamais vendue aussi vite !
          </h2>
          <div className="block-body">
            <p>
              Envie d’être accompagné dans la vente de votre véhicule et ainsi
              éviter les tracas avec les particuliers ? Confiez la vente de
              votre véhicule à Chasseurs d’Autos, le premier réseau
              d’indépendants adossé à de véritables professionnels de
              l’automobile. Nos experts s’occupent de tout.
            </p>
          </div>
          <div className="cards">
            <CardBuy title="Multi-diffusion" description="de votre annonce" />
            <CardBuy title="Inspection" description="de votre automobile" />
            <CardBuy
              title="Gestion de la relation"
              description="avec les acheteurs"
            />
            <CardBuy
              title="Prise de photo"
              description="avec mise en valeur de votre véhicule"
            />
            <CardBuy title="Sécurisation" description="de votre paiement" />
            <div className="actions-wrapper">
              <Button text="Vendre mon véhicule" />
            </div>
          </div>
        </BlockImage>
      </PageWrapper>
    </div>
  );
}
