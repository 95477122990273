import React, { useEffect, useState } from "react";
import Grid from "./grid";
import Button from "./button";
import CardCar from "./cardCar";
import { isDev } from "../shared/helpers";
import axiosInstance from "../shared/axiosInstance";
import setAccessToekn from "../shared/setAccessToken";
import { AxiosError } from "axios";

export default function cardsReassurance() {
  const [productsData, setProductsData] = useState<Array<any> | null>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const searchQuery = `SELECT+Id,Name,Version__c,PrixTTC__c,KmCompteur__c,Modele__c,DateMEC__c,Marque__c,LoyerMensuel__c+FROM+Vehicule__c${
          !isDev ? "+WHERE+Website_uploading__c+=+true" : ""
        }+LIMIT+5`;

        const { data } = await axiosInstance.get(
          `/services/data/v56.0/query?q=${searchQuery}`
        );
        setProductsData(data.records);
      } catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
          await setAccessToekn();
        }
      }
    };
    fetchProducts();
  }, []);
  console.log(productsData)
  return (
    <div className="latest">
      {productsData && productsData[0]?.Id && (
        <Grid
          container
          maxWidth="lg"
          columnSpacing={{
            xs: 2,
          }}
          rowSpacing={{
            xs: 8,
          }}
          sx={{
            px: 2,
          }}
          className="latest-wrapper"
        >
          <Grid
            xs={12}
            md={5.5}
            sx={{
              mb: 0,
            }}
          >
            <h2>
              <span className="small">
                ACHETER UN
                <span className="highlight"> VÉHICULE</span>
              </span>
              Nos dernières <br /> rentrées
            </h2>

            <CardCar
              id={1}
              productId={productsData[0].Id}
              name={productsData[0].Marque__c+" "+productsData[0].Modele__c}
              description={productsData[0].Version__c}
              price={productsData[0].PrixTTC__c|| null}
              location={productsData[0].LoyerMensuel__c}
              img={productsData[0].img}
              km={productsData[0].KmCompteur__c}
              year={new Date(productsData[0].DateMEC__c).getFullYear()}
            />
          </Grid>

          <Grid
            xs={12}
            md={6.5}
            sx={{
              mb: 0,
            }}
          >
            <Grid
              container
              maxWidth="lg"
              columnSpacing={4}
              sx={{
                p: 0,
              }}
            >
              {productsData.slice(1).map((car, i) => (
                <Grid
                  key={i + 1}
                  xs={12}
                  md={6}
                  sx={{
                    my: 0,
                    px: {
                      sm: 0,
                      md: 2,
                    },
                  }}
                >
                  <CardCar
                    id={i + 1}
                    productId={car.Id}
                    name={car.Marque__c+" "+car.Modele__c}
                    description={car.Version__c}
                    price={car.PrixTTC__c || null}
                    location={car.LoyerMensuel__c}
                    img={car.img}
                    km={car.KmCompteur__c}
                    year={new Date(car.DateMEC__c).getFullYear()}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid xs={12} className="latest-actions">
            <Button text="Voir tous les véhicules" outlined to="/acheter" />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
