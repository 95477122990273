import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { price_format } from "../shared/helpers";
import { fetchThumbnails } from "../shared/APIs";
import { SyncLoader } from "react-spinners";
import { InView } from "react-intersection-observer";

interface Props {
  productId?: string;
  type?: string;
  img?: string;
  name?: string;
  description?: string;
  price?: string;
  location?: string;
  id?: number;
  orange?: boolean;
  year?: number,
  km?: number
}

export default function Card({
                               productId,
                               name = "car name",
                               description = "car details",
                               price,
                               location,
                               type,
                               orange,
                               year,
                               km
                             }: Props) {
  const [image, setImage] = useState<string | undefined>(
      "https://placehold.co/600x400/fff/fff?text=No+Image"
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
  const [wasInView, setWasInView] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setIsImageLoading(true);
    setImage(undefined);
    setWasInView(false);
  }, [productId]);

  return (
      <Link className="card-link" to={`/produit?id=${productId}`}>
        <InView
            as="div"
            onChange={(inView) => {
              if (inView && !wasInView) {
                setWasInView(true);
                if (productId) {
                  fetchThumbnails(productId).then((dataImages) => {
                    if (dataImages && dataImages[0]) {
                      setImage(dataImages[0]);
                    } else {
                      setImage("https://placehold.co/600x400?text=No+Image");
                    }
                    setIsLoading(false);
                  });
                }
              }
            }}
        >
          <div
              className={`card card-car${type ? ` card-${type}` : " "}${
                  orange ? " card-orange" : ""
              }`}
          >
            {(isLoading || isImageLoading) && (
                <div className="loading-wrapper">
                  <div className="loading">
                    <SyncLoader
                        color="#7879f1"
                        loading={true}
                        size={10}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                  </div>
                </div>
            )}
            <img
                src={image}
                alt="car image"
                onLoad={() => setIsImageLoading(false)}
            />
            {!isImageLoading &&(
                <div className="car-infos">
                  {year ? <span>{year}</span> : null}
                  {km ? <span>{km} km</span> : null}
                </div>
            )}

            <div className="details">
              <div className="top">
                <p>
                  <span className="name">{name}</span>
                  <span className="description">{description}</span>
                </p>
                {price && (
                    <p className="price">
                      <span className="number">{price_format(price)}</span>
                      <span className="coin">€</span>
                    </p>
                )}
              </div>
              <div className="bottom">
                {location && (
                    <>
                      <p>Montant location</p>
                      <div>
                        <p>
                          À partir de <br /> {price_format(location)}€/mois*
                        </p>
                      </div>
                    </>
                )}
              </div>
            </div>
          </div>
        </InView>
      </Link>
  );
}