import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Agents from "../components/Agents";
import CardsReassurance from "../components/cardsReassurance";

export default function GuidPage() {
  return (
    <div className="page-guid">
      <PageWrapper>
        <Head>
          <b>Qui sommes-nous ?</b>
        </Head>
        <Agents />
        <CardsReassurance />
      </PageWrapper>
    </div>
  );
}
