import React from "react";
import Grid from "./grid";
import Button from "./button";

const network = [
  {
    region: "01 - AIN",
    agetns: [
      {
        fname: "HARBONNIER",
        lname: "Cindy",
        city: "TROYES",
      },
    ],
  },
  {
    region: "21 - COTE D'OR",
    agetns: [
      {
        fname: "FAUSSURIER",
        lname: "Yohan",
        city: "DIJON",
      },
      {
        fname: "GERARD",
        lname: "Hervé",
        city: "DIJON",
      },
    ],
  },
  {
    region: "25 - DOUBS",
    agetns: [
      {
        fname: "ABEL",
        lname: "Alexandre",
        city: "Besançon - PONTARLIER",
      },
    ],
  },
  {
    region: "29 - FINISTERE",
    agetns: [
      {
        fname: "DROUHIN",
        lname: "Christophe",
        city: "Quimper",
      },
      {
        fname: "BERY",
        lname: "Antoine",
        city: "Quimper",
      }
    ],
  },
  {
    region: "35 - ILE ET VILAINE",
    agetns: [
      {
        fname: "MALLE",
        lname: "Etienne",
        city: "Rennes",
      },
      {
        fname: "LEGER PROVOST",
        lname: "Jeremie",
        city: "Rennes",
      },
    ],
  },
  {
    region: "42 - LOIRE",
    agetns: [
      {
        fname: "BOZBIYIK",
        lname: "Aykut",
        city: "SAINT ETIENNE",
      },
    ],
  },
  {
    region: "44 - LOIRE ATLANTIQUE",
    agetns: [
      {
        fname: "DUTERTRE",
        lname: "Franck",
        city: "Nantes",
      },
    ],
  },
  {
    region: "45 - LOIRET",
    agetns: [
      {
        fname: "CARNEIRO",
        lname: "José",
        city: "GIEN - MONTARGIS",
      },
      {
        fname: "LAMANDE",
        lname: "Sébastien",
        city: "ORLÉANS - OLIVET",
      },
    ],
  },
  {
    region: "59 - NORD",
    agetns: [
      {
        fname: "VANSTEENE",
        lname: "Maxime",
        city: "LILLE",
      },
    ],
  },
  {
    region: "69 - RHONE",
    agetns: [
      {
        fname: "GOUSSI",
        lname: "Faycal",
        city: "LYON",
      },
    ],
  },
  {
    region: "71 - SAONE ET LOIRE",
    agetns: [
      {
        fname: "NEVEU",
        lname: "Bertrand",
        city: "MACON - BOURG EN BRESSE",
      },
      {
        fname: "GERARD",
        lname: "Hervé",
        city: "CHATENOY LE ROYAL",
      },
    ],
  },
  {
    region: "77 - SEINE ET MARNE",
    agetns: [
      {
        fname: "GAUVIN",
        lname: "Gael",
        city: "MEAUX",
      },
      {
        fname: "DE AZEVEDO",
        lname: "Wilson",
        city: "SENS - PROVINS",
      },
    ],
  },
  {
    region: "78 - YVELINES",
    agetns: [
      {
        fname: "CHATELAIN",
        lname: "Cyrille",
        city: "ACHERES",
      },
    ],
  },
  {
    region: "89 - YONNE",
    agetns: [
      {
        fname: "DE AZEVEDO",
        lname: "Wilson",
        city: "SENS - PROVINS",
      },
    ],
  },
  {
    region: "FRANCE",
    agetns: [
      {
        fname: "BOLLON",
        lname: "Cédric",
        city: "",
      },
    ],
  },


];

export default function Agents() {
  return (
    <div className="agents">
      <Grid container maxWidth="lg">
        <div className="wrapper">
          <div className="description">
            <p>
              Chasseurs d’Autos est un réseau d’indépendants en intermédiation
              de vente de véhicules adossé à des professionnels de l’automobile.
            </p>
            <br />
            <br />
            <p>
              Notre rôle ? Accompagner les clients, Particuliers et Entreprises,
              dans la vente de leur véhicule par le biais d’un mandat de vente
              ou d’un rachat cash immédiat. Véritables facilitateurs, nous nous
              occupons de tout… L’inspection du véhicule, la prise de photo, la
              diffusion de l’annonce du véhicule en mandat, la gestion de la
              relation avec les acheteurs, sans oublier la sécurisation du
              paiement de la vente entre deux particuliers.
            </p>
            <br />
            <br />
            <p>
              Nous sommes également les interlocuteurs privilégiés de nos
              clients, particuliers comme professionnels, pour la revente et le
              financement de véhicules neufs et d’occasions, toutes marques.
            </p>
            <br />
            <br />
            <p>
              Découvrez notre réseau et trouvez le Chasseur d’Autos le plus
              proche de chez vous
            </p>
          </div>
          {network.map((n, i) => (
            <div className="cards" key={i}>
              <h5>{n.region}</h5>
              {n.agetns.map((agent, i) => (
                <div className="card" key={i}>
                  <div className="card-wrapper">
                    <div className="top">
                      <div className="name">
                        <p className="firstname">{agent.fname}</p>
                        <p className="lastname">{agent.lname}</p>
                      </div>
                    </div>
                    <div className="bottom">
                      <p className="city">{agent.city}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </Grid>
    </div>
  );
}
