import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Head from "../components/head";
import PageWrapper from "../components/PageWrapper";
import Grid from "../components/grid";
import FormBuy from "../components/formBuy";
import CardCar from "../components/cardCar";
import PaginationItems from "../components/Pagination";
import { isDev } from "../shared/helpers";
import axiosInstance from "../shared/axiosInstance";
import setAccessToekn from "../shared/setAccessToken";
import { AxiosError } from "axios";
import { haveSameKeysAndValues } from "../shared/helpers";
import Loading from "../components/Loading";

export default function BuyPage() {
  const [productsData, setProductsData] = useState<any[] | null>(null);

  const [currentItems, setCurrentItems] = useState<Array<any>>([]);
  const [marques, setMarques] = useState<Array<any>>([]);
  const [modeles, setModeles] = useState<Array<any>>([]);
  const [carburants, setCarburants] = useState<Array<any>>([]);
  const [gearboxes, setGearBoxes] = useState<Array<any>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const objValue = (value: string) => {
    return { value, label: value };
  };

  type ObjValue = {
    value: string;
    label: string;
  };

  type ModeleObjValue = {
    marque: string;
    modeles: Array<ObjValue | string>;
  };

  type CarburantObjectValue = {
    marque: string;
    carburants: Array<ObjValue | string>;
  };

  type GearBoxObjectValue = {
    marque: string;
    gearboxes: Array<ObjValue | string>;
  };

  const location = useLocation();

  const formData = location.state?.formData;
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);

      let searchQuery = `SELECT+Id,Name,Version__c,TypeBoite__c,Energie__c,DateMEC__c,KmCompteur__c,Marque__c,Modele__c,PrixTTC__c,LoyerMensuel__c+FROM+Vehicule__c`;

      !isDev ? (searchQuery += "+WHERE+Website_uploading__c+=+true") : "";

      try {
        const { data } = await axiosInstance.get(
          `/services/data/v56.0/query?q=${searchQuery}`
        );

        setProductsData(data.records);

        let recordsMarques: Array<ObjValue | string> = [];
        const recordsModeles: Array<ModeleObjValue | string> = [];
        const recordsCarburants: Array<CarburantObjectValue | string> = [];
        const recordsGearboxes: Array<GearBoxObjectValue | string> = [];

        data.records.forEach(
          (car: {
            Marque__c: string;
            Modele__c: string;
            Energie__c: string;
            TypeBoite__c: string;
          }) => {
            // marques
            if (!recordsMarques.includes(car.Marque__c)) {
              recordsMarques.push(car.Marque__c);
            }

            // modeles
            if (!recordsModeles[car.Marque__c]) {
              recordsModeles[car.Marque__c] = { modeles: [] };
            }
            if (
              !recordsModeles[car.Marque__c].modeles.includes(car.Modele__c)
            ) {
              // const value: ObjValue = objValue(car.Modele__c);
              // recordsModeles[car.Marque__c].modeles.push(value);
              recordsModeles[car.Marque__c].modeles.push(car.Modele__c);
            }

            // carburants
            if (!recordsCarburants[car.Modele__c]) {
              recordsCarburants[car.Modele__c] = { carburants: [] };
            }
            if (
              !recordsCarburants[car.Modele__c].carburants.includes(
                car.Energie__c
              )
            ) {
              // const value: ObjValue = objValue(car.Modele__c);
              // recordsModeles[car.Marque__c].modeles.push(value);
              recordsCarburants[car.Modele__c].carburants.push(car.Energie__c);
            }

            // gearboxes
            if (!recordsGearboxes[car.Modele__c]) {
              recordsGearboxes[car.Modele__c] = { gearboxes: [] };
            }
            if (
              !recordsGearboxes[car.Modele__c].gearboxes.includes(
                car.TypeBoite__c
              )
            ) {
              // const value: ObjValue = objValue(car.Modele__c);
              // recordsModeles[car.Marque__c].modeles.push(value);
              recordsGearboxes[car.Modele__c].gearboxes.push(car.TypeBoite__c);
            }
          }
        );

        recordsMarques = recordsMarques.map((v: any) => objValue(v));

        Object.entries(recordsModeles).forEach(([key]) => {
          recordsModeles[key].modeles = recordsModeles[key].modeles.map(
            (mod: string) => objValue(mod)
          );
        });

        Object.entries(recordsCarburants).forEach(([key]) => {
          recordsCarburants[key].carburants = recordsCarburants[
            key
          ].carburants.map((mod: string) => objValue(mod));
        });

        Object.entries(recordsGearboxes).forEach(([key]) => {
          recordsGearboxes[key].gearboxes = recordsGearboxes[key].gearboxes.map(
            (mod: string) => objValue(mod)
          );
        });

        setMarques(recordsMarques);
        setModeles(recordsModeles);
        setCarburants(recordsCarburants);
        setGearBoxes(recordsGearboxes);
        setIsLoading(false);
      } catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
          await setAccessToekn();
          return;
        }
        setIsLoading(false);
      }
    };
    fetchProducts();
  }, []);
  const currentItemsRender = (currentItemsData) => {
    if (haveSameKeysAndValues(currentItemsData, currentItems)) {
      return;
    } else {
      setCurrentItems(currentItemsData);
      const element = document.querySelector(".form-buy-2");
      if (element) {
        // element.scrollIntoView();
        hrefScroll();
      }
    }
  };

  const handleFormData = (data: any) => {
    setProductsData(data);
    const element = document.querySelector(".form-buy-2");
    if (element) {
      hrefScroll();
    }
  };

  const hrefScroll = () => {
    // Create a new <a> element
    const scrollToTopLink = document.createElement("a");

    // Set the href attribute to #
    scrollToTopLink.href = "#car-items";

    // Set some styles to make the link invisible
    scrollToTopLink.style.position = "fixed";
    scrollToTopLink.style.bottom = "20px";
    scrollToTopLink.style.right = "20px";
    scrollToTopLink.style.opacity = "0";

    const carItemsElement = document.getElementById("car-items");

    if (carItemsElement) {
      carItemsElement.insertBefore(scrollToTopLink, carItemsElement.firstChild);
    } else {
      document.body.appendChild(scrollToTopLink);
    }

    // Simulate a click event on the link to scroll the page up
    scrollToTopLink.click();
  };

  if (isLoading) return <Loading />;
  console.log(currentItems)
  return (
    <div className="page-buy">
      <PageWrapper>
        <Head light>J’achète mon véhicule avec Chasseurs d’Autos</Head>
        <Grid
          container
          maxWidth="lg"
          sx={{
            my: {
              md: 14,
            },
            px: {
              md: 1,
            },
          }}
          className="buy-filter"
        >
          <Grid
            xs={12}
            md={3.5}
            sx={{
              mt: {
                md: 0,
              },
            }}
          >
            <FormBuy
              formData={formData}
              marqueOptions={marques}
              modeleOptions={modeles}
              carburantOptions={carburants}
              gearboxesOptions={gearboxes}
              handleFormData={handleFormData}
            />
          </Grid>
          <Grid
            xs={12}
            md={8.5}
            sx={{
              my: 0,
            }}
            id="car-items"
          >
            <Grid container>
              {currentItems &&
                currentItems.map((car, k) => (
                  <Grid
                    key={k}
                    xs={12}
                    md={4}
                    sx={{
                      my: {
                        xs: 2,
                        md: 0,
                      },
                      mx: 0,
                      py: {
                        md: 1.5,
                      },
                      px: {
                        sm: 2,
                      },
                    }}
                  >
                    <CardCar
                      id={k}
                      productId={car.Id}
                      type="car-2"
                      name={car.Marque__c + " " + car.Modele__c}
                      description={car.Version__c}
                      price={car.PrixTTC__c || null}
                      location={car.LoyerMensuel__c}
                      img={car.img}
                      km={car.KmCompteur__c}
                      year={new Date(car.DateMEC__c).getFullYear()}
                    />
                  </Grid>
                ))}
            </Grid>
            <PaginationItems
              itemsPerPage={12}
              currentItemsRender={currentItemsRender}
              items={productsData || []}
            />
            {currentItems.length >= 1 && (
              <p className="indecate">
                *Un crédit vous engage et doit être remboursé, vérifiez vos
                capacités de remboursement
              </p>
            )}
            {currentItems.length <= 0 && (
              <p className="message">pas de voiture trouvée !</p>
            )}
          </Grid>
        </Grid>
        {/*
            <BlockOther />
          */}
      </PageWrapper>
    </div>
  );
}
