import moment from 'moment';
import 'moment/locale/fr'; // Import the French locale
moment.locale('fr');

const form_1_1 = (data?: any) => `<p>Visualisez cette location avec option d'achat grâce à un exemple représentatif. 
Pour une Location avec Option d’Achat (LOA) de ${data.duration} mois et sur un kilométrage de ${data.credit_mile_age.toFixed(2)} km/an pour un véhicule vendu au prix de ${data.amount.toFixed(2)}€ avec un premier loyer de ${data.first_rent.toFixed()}€, suivis de ${data.number_of_terms} loyers mensuels de ${data.monthly_payment} € (1), option d’achat finale de ${data.vr_amount} € TTC soit un montant total dû sans option d’achat finale de ${data.total_amount - data.vr_amount} € TTC. Montant total dû avec option d'achat finale : ${data.total_amount} € TTC. </p>

<p className="small">
(1) Le montant des loyers intègre le montant mensuel de la prime d'assurance Garantie Perte Financière qui est de ${data.monthly_cost_insurance} € TTC par mois. Coût total de cette assurance ${data.total_cost_issurance} € TTC. La souscription à cette assurance est nécessaire pour l'obtention de cette location avec option d'achat. La Garantie Perte Financière est associée à cette offre de location avec option d’achat. Cette assurance permet le paiement d’un capital en cas de vol ou de destruction totale du véhicule loué, dans les conditions et limites de garanties disponibles sur simple demande. Vous conservez la possibilité de souscrire une assurance équivalente auprès d'une compagnie d'assurances de votre choix. Par ailleurs, Cofica Bail propose des solutions de location avec option d'achat sans Garantie Perte Financière associée. La Garantie Perte Financière est distribuée par Cofica Bail en qualité de courtier en assurance et est un produit de Cardif Assurances Risques Divers - S.A. au capital de 21 602 240 € – 308 896 547 RCS Paris – siège social : 1 bd Haussmann 75009 Paris - pour les garanties Valeur d'Achat, Assurance complémentaire et Evènements imprévus et Icare Assurance - S.A. au capital de 2 358 816 € – 327 061 339 RCS Nanterre – siège social : 93, rue Nationale 92100 Boulogne-Billancourt - pour la garantie d'assistance Véhicule de remplacement. Entreprises régies par le Code des assurances. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 place de Budapest 75436 Paris Cedex 9.
<br/><br/>
Offre de location avec option d'achat, valable jusqu’au ${moment(data.offer_end_date).format('DD MMMM YYYY, H:mm')}, proposée par Cofica Bail, bailleur et réservée aux particuliers. 
Sous réserve d'étude et d'acceptation de votre dossier par Cofica Bail. Vous disposez d'un droit de rétractation. Cofica Bail, détenue à 100% par BNP Paribas Personal Finance, S.A. au capital de 14 485 544,00 € - 399 181 924 RCS Paris, Siège social : 1 boulevard Haussmann 75009 Paris N°ORIAS : 07 023 197 (www.orias.fr).  
Cofica Bail et BNP Paribas Personal Finance sont des sociétés soumises à l'Autorité de Contrôle Prudentiel et de Résolution, 4 Place de Budapest - 75436 Paris Cedex 09. 
Conditions en vigueur au ${moment().format('DD MMMM YYYY')} 
</p>
`;

const form_1_2 = (data?: any) => `<p>Visualisez cette location avec option d'achat grâce à un exemple représentatif. 
Pour une Location avec Option d’Achat (LOA) de ${data.duration} mois et sur un kilométrage de ${data.credit_mile_age}/an pour un véhicule vendu au prix de ${data.amount.toFixed(2)}€ avec un premier loyer de ${data.first_rent}€, suivis de ${data.number_of_terms} loyers mensuels de ${data.monthly_payment.toFixed(2)}€ (1), option d’achat finale de ${data.vr_amount.toFixed(2)}€ TTC soit un montant total dû sans option d’achat finale de ${data.total_amount - data.vr_amount}€ TTC. Montant total dû avec option d'achat finale : ${data.total_amount}€ TTC. 
</p>

<p className="small">
(1) Le montant des loyers intègre le montant mensuel de la prime d'assurance Garantie Perte Financière qui est de ${data.monthly_cost_insurance.toFixed(2)}€ TTC par mois. Coût total de cette assurance ${data.total_cost_insurance.toFixed(2)}€ TTC. La souscription à cette assurance est nécessaire pour l'obtention de cette location avec option d'achat. La Garantie Perte Financière est associée à cette offre de location avec option d’achat. Cette assurance permet le paiement d’un capital en cas de vol ou de destruction totale du véhicule loué, dans les conditions et limites de garanties disponibles sur simple demande. Vous conservez la possibilité de souscrire une assurance équivalente auprès d'une compagnie d'assurances de votre choix. Par ailleurs, Cofica Bail propose des solutions de location avec option d'achat sans Garantie Perte Financière associée. La Garantie Perte Financière est distribuée par Cofica Bail en qualité de courtier en assurance et est un produit de Cardif Assurances Risques Divers - S.A. au capital de 21 602 240 € – 308 896 547 RCS Paris – siège social : 1 bd Haussmann 75009 Paris - pour les garanties Valeur d'Achat, Assurance complémentaire et Evènements imprévus et Icare Assurance - S.A. au capital de 2 358 816 € – 327 061 339 RCS Nanterre – siège social : 93, rue Nationale 92100 Boulogne-Billancourt - pour la garantie d'assistance Véhicule de remplacement. Entreprises régies par le Code des assurances. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 place de Budapest 75436 Paris Cedex 9. 

Le coût mensuel de l’Extension de Garantie est de [XXX] € TTC par mois. Le coût total de cette assurance est de [WWW] €. 
Le Contrat d’Extension de Garantie est un produit d’ICARE Assurance : 93 rue Nationale, 92100 Boulogne-Billancourt, société anonyme au capital de 2 358 816 euros, RCS Nanterre 327 061 339, entreprise régie par le Code des assurances et soumise à l’Autorité de Contrôle Prudentiel et de Résolution, 4, place de Budapest, 75436 Paris Cedex 09. ICARE Assurance, a confié la gestion de ses produits à ICARE, 93, rue Nationale, 92100 Boulogne-Billancourt, société anonyme au capital de 4 620 825 euros, RCS Nanterre B 378 491 690. Ce produit est proposé par COFICA BAIL : 1, boulevard Haussmann 75009 Paris, société anonyme au capital de 14 485 544 euros, RCS Paris 399 181 924, ORIAS No 07 023 197 (www.orias.fr), en qualité de société de courtage d’assurances percevant des commissions, sans être soumise à une obligation contractuelle de travailler exclusivement avec l’assureur. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de Résolution, 4, place de Budapest, 75436 Paris Cedex 9. Registre des Intermédiaires d’assurance (ORIAS), 1, rue Jules Lefebvre, 75431 Paris Cedex 9. 
<br/> <br/>
Offre de location avec option d'achat, valable jusqu’au ${moment(data.offer_end_date).format('DD MMMM YYYY, H:mm')}, proposée par Cofica Bail, bailleur et réservée aux particuliers. 
Sous réserve d'étude et d'acceptation de votre dossier par Cofica Bail. Vous disposez d'un droit de rétractation. Cofica Bail, détenue à 100% par BNP Paribas Personal Finance, S.A. au capital de 14 485 544,00 € - 399 181 924 RCS Paris, Siège social : 1 boulevard Haussmann 75009 Paris N°ORIAS : 07 023 197 (www.orias.fr).  
Cofica Bail et BNP Paribas Personal Finance sont des sociétés soumises à l'Autorité de Contrôle Prudentiel et de Résolution, 4 Place de Budapest - 75436 Paris Cedex 09. 
Conditions en vigueur au ${moment().format('DD MMMM YYYY')} </p>
`;
const form_1_3 = (data: any) => `<p>Visualisez cette location avec option d'achat grâce à un exemple représentatif. 
Pour une Location avec Option d’Achat (LOA) de ${data.duration} mois et sur un kilométrage de ${data.credit_mile_age}/an pour un véhicule vendu au prix de ${data.amount.toFixed(2)}€ avec un premier loyer de ${data.first_rent.toFixed(2)}€, suivis de ${data.number_of_terms} loyers mensuels de ${data.monthly_payment.toFixed(2)}€ (1), option d’achat finale de ${data.vr_amount.toFixed(2)}€ TTC soit un montant total dû sans option d’achat finale de ${(data.total_amount - data.vr_amount).toFixed(2)}€ TTC. Montant total dû avec option d'achat finale : ${data.total_amount.toFixed(2)}€ TTC. </p>

<p className="small">
(1) Le montant des loyers intègre le montant mensuel de la prime d'assurance Garantie Perte Financière qui est de ${data.monthly_cost_insurance.toFixed(2)}€ TTC par mois. Coût total de cette assurance ${data.total_cost_insurance.toFixed(2)}€ TTC. La souscription à cette assurance est nécessaire pour l'obtention de cette location avec option d'achat. La Garantie Perte Financière est associée à cette offre de location avec option d’achat. Cette assurance permet le paiement d’un capital en cas de vol ou de destruction totale du véhicule loué, dans les conditions et limites de garanties disponibles sur simple demande. Vous conservez la possibilité de souscrire une assurance équivalente auprès d'une compagnie d'assurances de votre choix. Par ailleurs, Cofica Bail propose des solutions de location avec option d'achat sans Garantie Perte Financière associée. La Garantie Perte Financière est distribuée par Cofica Bail en qualité de courtier en assurance et est un produit de Cardif Assurances Risques Divers - S.A. au capital de 21 602 240 € – 308 896 547 RCS Paris – siège social : 1 bd Haussmann 75009 Paris - pour les garanties Valeur d'Achat, Assurance complémentaire et Evènements imprévus et Icare Assurance - S.A. au capital de 2 358 816 € – 327 061 339 RCS Nanterre – siège social : 93, rue Nationale 92100 Boulogne-Billancourt - pour la garantie d'assistance Véhicule de remplacement. Entreprises régies par le Code des assurances. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 place de Budapest 75436 Paris Cedex 9. 
<br/><br/>
Le coût mensuel du Pack Excellence est de [XXX] € TTC par mois. Le coût total de cette prestation est de [WWW] €. 
</p>
<p>

Détails de l’éligibilité, des conditions et limites dans la notice du produit disponible dans votre point de vente. Pack Excellence est un produit mixte optionnel, porté par Icare Assurance pour la partie extension de garantie et assistance – S.A au capital de 2 358 816,00 € - 378 491 690 RCS Nanterre et par Icare pour la partie entretien – S.A au capital de 4 620 825,00 € -378491690 RCS Nanterre. Icare Assurance et Icare : 93 rue Nationale 92100 Boulogne-Billancourt. Ce produit est souscrit et proposé par Cofica Bail. 
</p>
<p className="small">
Offre de location avec option d'achat, valable jusqu’au ${moment(data.offer_end_date).format('DD MMMM YYYY, H:mm')}, proposée par Cofica Bail, bailleur et réservée aux particuliers. 
Sous réserve d'étude et d'acceptation de votre dossier par Cofica Bail. Vous disposez d'un droit de rétractation. Cofica Bail, détenue à 100% par BNP Paribas Personal Finance, S.A. au capital de 14 485 544,00 € - 399 181 924 RCS Paris, Siège social : 1 boulevard Haussmann 75009 Paris N°ORIAS : 07 023 197 (www.orias.fr).  
Cofica Bail et BNP Paribas Personal Finance sont des sociétés soumises à l'Autorité de Contrôle Prudentiel et de Résolution, 4 Place de Budapest - 75436 Paris Cedex 09. 
Conditions en vigueur au ${moment().format('DD MMMM YYYY')} 
</p>
`;

const form_2_1 = (data: any) => `<p>Visualisez cette location avec option d'achat grâce à un exemple représentatif. 
Pour une Location avec Option d’Achat (LOA) de ${data.duration} mois et sur un kilométrage de ${data.credit_mile_age} km/an pour un véhicule vendu au prix de ${data.amount.toFixed(2)}€ avec un premier loyer de ${data.first_rent.toFixed(2)}€, suivis de ${data.number_of_terms} loyers mensuels de ${data.monthly_payment.toFixed(2)}€ (1), option d’achat finale de ${data.vr_amount.toFixed(2)}€ TTC soit un montant total dû sans option d’achat finale de ${(data.total_amount - data.vr_amount).toFixed(2)}€ TTC. Montant total dû avec option d'achat finale : ${data.total_amount.toFixed(2)}€ TTC. 
</p>

<p>
Le coût mensuel de l’assurance facultative Décès, Perte Totale et Irréversible d’Autonomie, Incapacité Temporaire Totale de travail (souscrite auprès de Cardif Assurance Vie - S.A au capital de 719 167 488 € - 732 028 154 RCS Paris - et Cardif Assurances Risques Divers - S.A au capital de 21 602 240 € - 308 896 547 RCS Paris - Siège social : 1, Bd Haussmann 75009 Paris. Entreprises régies par le code des assurances) est de ${data.monthly_cost_insurance.toFixed(2)}€ par mois et s’ajoute au montant du loyer. Le coût total de cette assurance est de ${data.total_cost_insurance.toFixed(2)}€. Détails des conditions et limites de garantie disponibles dans la notice sur simple demande.  
  </p>

<p className="small">

(1) Le montant des loyers intègre le montant mensuel de la prime d'assurance Garantie Perte Financière qui est de ${data.monthly_cost_insurance.toFixed(2)}€ TTC par mois. Coût total de cette assurance ${data.total_cost_insurance.toFixed(2)}€ TTC. La souscription à cette assurance est nécessaire pour l'obtention de cette location avec option d'achat. La Garantie Perte Financière est associée à cette offre de location avec option d’achat. Cette assurance permet le paiement d’un capital en cas de vol ou de destruction totale du véhicule loué, dans les conditions et limites de garanties disponibles sur simple demande. Vous conservez la possibilité de souscrire une assurance équivalente auprès d'une compagnie d'assurances de votre choix. Par ailleurs, Cofica Bail propose des solutions de location avec option d'achat sans Garantie Perte Financière associée. La Garantie Perte Financière est distribuée par Cofica Bail en qualité de courtier en assurance et est un produit de Cardif Assurances Risques Divers - S.A. au capital de 21 602 240 € – 308 896 547 RCS Paris – siège social : 1 bd Haussmann 75009 Paris - pour les garanties Valeur d'Achat, Assurance complémentaire et Evènements imprévus et Icare Assurance - S.A. au capital de 2 358 816 € – 327 061 339 RCS Nanterre – siège social : 93, rue Nationale 92100 Boulogne-Billancourt - pour la garantie d'assistance Véhicule de remplacement. Entreprises régies par le Code des assurances. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 place de Budapest 75436 Paris Cedex 9. </p>
<p className="small">
Offre de location avec option d'achat, valable jusqu’au ${moment(data.offer_end_date).format('DD MMMM YYYY, H:mm')}, proposée par Cofica Bail, bailleur et réservée aux particuliers. 
Sous réserve d'étude et d'acceptation de votre dossier par Cofica Bail. Vous disposez d'un droit de rétractation. Cofica Bail, détenue à 100% par BNP Paribas Personal Finance, S.A. au capital de 14 485 544,00 € - 399 181 924 RCS Paris, Siège social : 1 boulevard Haussmann 75009 Paris N°ORIAS : 07 023 197 (www.orias.fr).  
Cofica Bail et BNP Paribas Personal Finance sont des sociétés soumises à l'Autorité de Contrôle Prudentiel et de Résolution, 4 Place de Budapest - 75436 Paris Cedex 09. 
Conditions en vigueur au ${moment().format('DD MMMM YYYY')} </p>
`;

const form_2_2 = (data: any) =>`<p>Visualisez cette location avec option d'achat grâce à un exemple représentatif. 
Pour une Location avec Option d’Achat (LOA) de ${data.duration} mois et sur un kilométrage de ${data.credit_mile_age} km/an pour un véhicule vendu au prix de ${data.amount.toFixed(2)}€ avec un premier loyer de ${data.first_rent.toFixed(2)}€, suivis de ${data.number_of_terms} loyers mensuels de ${data.monthly_payment.toFixed(2)}€ (1), option d’achat finale de ${data.vr_amount.toFixed(2)}€ TTC soit un montant total dû sans option d’achat finale de ${(data.total_amount - data.vr_amount).toFixed(2)}€ TTC. Montant total dû avec option d'achat finale : ${data.total_amount}€ TTC. </p>

<p>
Le coût mensuel de l’assurance facultative Décès, Perte Totale et Irréversible d’Autonomie, Incapacité Temporaire Totale de travail (souscrite auprès de Cardif Assurance Vie - S.A au capital de 719 167 488 € - 732 028 154 RCS Paris - et Cardif Assurances Risques Divers - S.A au capital de 21 602 240 € - 308 896 547 RCS Paris - Siège social : 1, Bd Haussmann 75009 Paris. Entreprises régies par le code des assurances) est de ${data.monthly_cost_insurance.toFixed(2)}€ par mois et s’ajoute au montant du loyer. Le coût total de cette assurance est de ${data.total_cost_insurance.toFixed(2)}€. Détails des conditions et limites de garantie disponibles dans la notice sur simple demande.</p>

<p class="small">
(1) Le montant des loyers intègre le montant mensuel de la prime d'assurance Garantie Perte Financière qui est de ${data.monthly_cost_insurance.toFixed(2)}€ TTC par mois. Coût total de cette assurance ${data.total_cost_insurance}€ TTC. La souscription à cette assurance est nécessaire pour l'obtention de cette location avec option d'achat. La Garantie Perte Financière est associée à cette offre de location avec option d’achat. Cette assurance permet le paiement d’un capital en cas de vol ou de destruction totale du véhicule loué, dans les conditions et limites de garanties disponibles sur simple demande. Vous conservez la possibilité de souscrire une assurance équivalente auprès d'une compagnie d'assurances de votre choix. Par ailleurs, Cofica Bail propose des solutions de location avec option d'achat sans Garantie Perte Financière associée. La Garantie Perte Financière est distribuée par Cofica Bail en qualité de courtier en assurance et est un produit de Cardif Assurances Risques Divers - S.A. au capital de 21 602 240 € – 308 896 547 RCS Paris – siège social : 1 bd Haussmann 75009 Paris - pour les garanties Valeur d'Achat, Assurance complémentaire et Evènements imprévus et Icare Assurance - S.A. au capital de 2 358 816 € – 327 061 339 RCS Nanterre – siège social : 93, rue Nationale 92100 Boulogne-Billancourt - pour la garantie d'assistance Véhicule de remplacement. Entreprises régies par le Code des assurances. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 place de Budapest 75436 Paris Cedex 9. 
</p>
<p class="small">
Le coût mensuel de l’Extension de Garantie est de [XXX] € TTC par mois. Le coût total de cette assurance est de [WWW] €. 
Le Contrat d’Extension de Garantie est un produit d’ICARE Assurance : 93 rue Nationale, 92100 Boulogne-Billancourt, société anonyme au capital de 2 358 816 euros, RCS Nanterre 327 061 339, entreprise régie par le Code des assurances et soumise à l’Autorité de Contrôle Prudentiel et de Résolution, 4, place de Budapest, 75436 Paris Cedex 09. ICARE Assurance, a confié la gestion de ses produits à ICARE, 93, rue Nationale, 92100 Boulogne-Billancourt, société anonyme au capital de 4 620 825 euros, RCS Nanterre B 378 491 690. Ce produit est proposé par COFICA BAIL : 1, boulevard Haussmann 75009 Paris, société anonyme au capital de 14 485 544 euros, RCS Paris 399 181 924, ORIAS No 07 023 197 (www.orias.fr), en qualité de société de courtage d’assurances percevant des commissions, sans être soumise à une obligation contractuelle de travailler exclusivement avec l’assureur. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de Résolution, 4, place de Budapest, 75436 Paris Cedex 9. Registre des Intermédiaires d’assurance (ORIAS), 1, rue Jules Lefebvre, 75431 Paris Cedex 9. 
</p>
<p class="small">
Offre de location avec option d'achat, valable jusqu’au ${moment(data.offer_end_date).format('DD MMMM YYYY, H:mm')}, proposée par Cofica Bail, bailleur et réservée aux particuliers. 
Sous réserve d'étude et d'acceptation de votre dossier par Cofica Bail. Vous disposez d'un droit de rétractation. Cofica Bail, détenue à 100% par BNP Paribas Personal Finance, S.A. au capital de 14 485 544,00 € - 399 181 924 RCS Paris, Siège social : 1 boulevard Haussmann 75009 Paris N°ORIAS : 07 023 197 (www.orias.fr).  
Cofica Bail et BNP Paribas Personal Finance sont des sociétés soumises à l'Autorité de Contrôle Prudentiel et de Résolution, 4 Place de Budapest - 75436 Paris Cedex 09. 
Conditions en vigueur au ${moment().format('DD MMMM YYYY')} </p>
`;

const form_2_3 = (data: any) => `<p>Visualisez cette location avec option d'achat grâce à un exemple représentatif. 
Pour une Location avec Option d’Achat (LOA) de ${data.duration} mois et sur un kilométrage de ${data.credit_mile_age} km/an pour un véhicule vendu au prix de ${data.amount.toFixed(2)}€ avec un premier loyer de ${data.first_rent.toFixed(2)}€, suivis de ${data.number_of_terms} loyers mensuels de ${data.monthly_payment.toFixed(2)}€ (1), option d’achat finale de ${data.vr_amount}€ TTC soit un montant total dû sans option d’achat finale de ${(data.total_amount - data.vr_amount).toFixed(2)}€ TTC. Montant total dû avec option d'achat finale : ${data.total_amount.toFixed(2)}€ TTC. </p>

<p>Le coût mensuel de l’assurance facultative Décès, Perte Totale et Irréversible d’Autonomie, Incapacité Temporaire Totale de travail (souscrite auprès de Cardif Assurance Vie - S.A au capital de 719 167 488 € - 732 028 154 RCS Paris - et Cardif Assurances Risques Divers - S.A au capital de 21 602 240 € - 308 896 547 RCS Paris - Siège social : 1, Bd Haussmann 75009 Paris. Entreprises régies par le code des assurances) est de ${data.monthly_cost_insurance.toFixed(2)}€ par mois et s’ajoute au montant du loyer. Le coût total de cette assurance est de ${data.total_cost_insurance.toFixed(2)}€. Détails des conditions et limites de garantie disponibles dans la notice sur simple demande.</p>

<p class="small">
(1) Le montant des loyers intègre le montant mensuel de la prime d'assurance Garantie Perte Financière qui est de ${data.monthly_cost_insurance.toFixed(2)}€ TTC par mois. Coût total de cette assurance ${data.total_cost_insurance.toFixed(2)}€ TTC. La souscription à cette assurance est nécessaire pour l'obtention de cette location avec option d'achat. La Garantie Perte Financière est associée à cette offre de location avec option d’achat. Cette assurance permet le paiement d’un capital en cas de vol ou de destruction totale du véhicule loué, dans les conditions et limites de garanties disponibles sur simple demande. Vous conservez la possibilité de souscrire une assurance équivalente auprès d'une compagnie d'assurances de votre choix. Par ailleurs, Cofica Bail propose des solutions de location avec option d'achat sans Garantie Perte Financière associée. La Garantie Perte Financière est distribuée par Cofica Bail en qualité de courtier en assurance et est un produit de Cardif Assurances Risques Divers - S.A. au capital de 21 602 240 € – 308 896 547 RCS Paris – siège social : 1 bd Haussmann 75009 Paris - pour les garanties Valeur d'Achat, Assurance complémentaire et Evènements imprévus et Icare Assurance - S.A. au capital de 2 358 816 € – 327 061 339 RCS Nanterre – siège social : 93, rue Nationale 92100 Boulogne-Billancourt - pour la garantie d'assistance Véhicule de remplacement. Entreprises régies par le Code des assurances. Sociétés soumises à l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), 4 place de Budapest 75436 Paris Cedex 9. 
</p>
<p class="small">
Le coût mensuel du Pack Excellence est de [XXX] € TTC par mois. Le coût total de cette prestation est de [WWW] €. 
</p>
<p>
Détails de l’éligibilité, des conditions et limites dans la notice du produit disponible dans votre point de vente. Pack Excellence est un produit mixte optionnel, porté par Icare Assurance pour la partie extension de garantie et assistance – S.A au capital de 2 358 816,00 € - 378 491 690 RCS Nanterre et par Icare pour la partie entretien – S.A au capital de 4 620 825,00 € -378491690 RCS Nanterre. Icare Assurance et Icare : 93 rue Nationale 92100 Boulogne-Billancourt. Ce produit est souscrit et proposé par Cofica Bail. 
</p>
<p class="small">
Offre de location avec option d'achat, valable jusqu’au ${moment(data.offer_end_date).format('DD MMMM YYYY, H:mm')}, proposée par Cofica Bail, bailleur et réservée aux particuliers. 
Sous réserve d'étude et d'acceptation de votre dossier par Cofica Bail. Vous disposez d'un droit de rétractation. Cofica Bail, détenue à 100% par BNP Paribas Personal Finance, S.A. au capital de 14 485 544,00 € - 399 181 924 RCS Paris, Siège social : 1 boulevard Haussmann 75009 Paris N°ORIAS : 07 023 197 (www.orias.fr).  
Cofica Bail et BNP Paribas Personal Finance sont des sociétés soumises à l'Autorité de Contrôle Prudentiel et de Résolution, 4 Place de Budapest - 75436 Paris Cedex 09. 
Conditions en vigueur au ${moment().format('DD MMMM YYYY')} </p>`;

export { form_1_1, form_1_2, form_1_3, form_2_1, form_2_2, form_2_3 };
