import axiosInstance from "./axiosInstance";
import setAccessToekn from "./setAccessToken";
import { AxiosError } from "axios";
import dotenv from 'dotenv';

const fetchImages = async (cardId: string) => {
    // cardId = "a007Z00000Oy3ipQAB";
    try {
        const apiCall = await axiosInstance.get(
            `/services/apexrest/api/images/getByIds/${cardId}`
        );
        const images = apiCall.data
            .flatMap((e: any) => e.images)
            .sort((a, b) => {
                if (a.isDefaultImage === b.isDefaultImage) {
                    return 0;
                }
                return a.isDefaultImage ? -1 : 1;
            })
            .map((c) => `https://image-compressor-api.lagentx.tech/xet?url=${encodeURIComponent(c.imgURL)}&quality=${process.env.REACT_APP_SLIDESHOW_QUALITY}&slash=${process.env.REACT_APP_SLIDESHOW_SLASH}&cat=${process.env.REACT_APP_SLIDESHOW_CAT}`);
        return images;
    }
    catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToekn();
        }
    }
};


const fetchThumbnails = async (cardId: string) => {
    try {
        const images:any = []
        const apiCallThumbnail = await axiosInstance.get(
            `/services/apexrest/api/images/getDefaultByIds/${cardId}`
        );
        apiCallThumbnail.data.forEach((e)=>{
            e.images.forEach(c=>{
                images.push(`https://image-compressor-api.lagentx.tech/xet?url=${encodeURIComponent(c.imgURL)}&quality=${process.env.REACT_APP_THUMBNAIL_QUALITY}&slash=${process.env.REACT_APP_THUMBNAIL_SLASH}&cat=${process.env.REACT_APP_THUMBNAIL_CAT}`)
            })
        })
        return images
    } catch (err) {
        console.log("Error:", (err as Error).message);
        if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToekn();
        }
    }
};
export { fetchImages, fetchThumbnails };