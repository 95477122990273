import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Grid from "./grid";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Button from "./button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { radioUncheck, numberPlateFormat, isDev } from "../shared/helpers";
import ReCAPTCHA from "react-google-recaptcha";

import { yupResolver } from "@hookform/resolvers/yup";

const recaptchaToken = process.env.REACT_APP_RECAPTCHA_TOKEN;

yup.setLocale({
  mixed: {
    required: "Ce champ est obligatoire",
  },
});
const schema = yup.object().shape({
  first_name: yup.string().max(40).required(),
  last_name: yup.string().max(80).required(),
  mobile: yup.number().typeError("Doit être un nombre").required(),
  city: yup.string().max(40).required(),
  email: yup
      .string()
      .email("S'il vous plaît, mettez une adresse email valide")
      .max(80)
      .required(),
  "00N68000003PFn9": yup.string().required(), // Date
  "00N68000003PFni": yup.string().required(), // Houre
  "00N68000003PFnx": yup
      .string()
      .oneOf(["1"], "Vous devez accepter les Termes")
      .required("Vous devez accepter les Termes"), // CandT
});

const houres = [
  { value: "8h-10h", label: "8h-10h" },
  { value: "10h-12h", label: "10h-12h" },
  { value: "12h-14h", label: "12h-14h" },
  { value: "14h-16h", label: "14h-16h" },
  { value: "16h-18h", label: "16h-18h" },
  { value: "18h-20h", label: "18h-20h" },
];

export default function FormBuyDirect({ carId }: { carId: string }) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const location = useLocation();

  const formData = location.state?.formData;

  const [captcha, setCaptcha] = useState({ isNotRobot: false, error: false });

  const watchCity = watch("city");

  function onChange(value) {
    if (value) {
      setCaptcha({ isNotRobot: true, error: false });
    }
  }

  const onSubmit = (data: any, e: any) => {
    if (!captcha.isNotRobot) {
      setCaptcha({ isNotRobot: false, error: true });
      return;
    }

    setTimeout(() => {
      e.target.submit();
    }, 0);
  };

  useEffect(() => {
    if (watchCity) {
      setValue("city", watchCity.toUpperCase());
    }
  }, [watchCity]);

  useEffect(() => {
    // radio uncheck
    radioUncheck(getValues, setValue);
    const subscription = watch((value, { name, type }) =>
        console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  return (
      <Grid container maxWidth="lg" columnSpacing={8}>
        <Grid xs={12} px={0}>
          <form
              className="form form-buy-direct"
              action={`https://${
                  isDev ? "test" : "webto"
              }.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`}
              method="POST"
              onSubmit={handleSubmit(onSubmit)}
          >
            <input
                type="hidden"
                name="oid"
                value={isDev ? "00D7Z0000005J1X" : "00D680000029HFM"}
            />
            <input
                type="hidden"
                name="retURL"
                value={
                  isDev
                      ? "https://chasseurs-auto.lagentx.tech?acheter=true&statut=succes"
                      : "https://chasseursdautos.com?acheter=true&statut=succes"
                }
            />

            <select id="lead_source" name="lead_source" hidden>
              <option selected value="Formulaire_site_web"></option>
            </select>
            <input
                id="utm_source" name="00N68000003PFoM" type="hidden" />
            <input
                id="utm_medium" name="00N68000003PFoH" type="hidden" />
            <input
                id="utm_term" name="00N68000003PFoR" type="hidden" />
            <input
                id="utm_campaign" name="00N68000003PFo7" type="hidden" />
            <input
                id="utm_content" name="00N68000003PFoC" type="hidden" />

            <input type="hidden" name="00N68000004VsQC" value={carId} />

            <Grid
                container
                maxWidth="lg"
                className="form-content"
                spacing={0}
                px={0}
            >
              <Grid
                  xs={12}
                  md={12}
                  mdOffset={0}
                  sx={{
                    my: 0,
                  }}
              >
                <p className="form-title">Informations personnelles</p>
                <Grid
                    container
                    maxWidth="lg"
                    className="form-controllers"
                    spacing={0}
                >
                  <Grid xs={12} md={5.5} mdOffset={0}>
                    <label className="form-label" htmlFor="first_name">
                      Prénom*
                    </label>
                    <input
                        autoComplete="off"
                        className="form-control"
                        placeholder="Jean"
                        {...register("first_name")}
                    />

                    {errors["first_name"]?.message && (
                        <p className="error">
                          {errors["first_name"] &&
                              (errors["first_name"] as { message: string }).message}
                        </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={5.5} mdOffset={1}>
                    <label className="form-label" htmlFor="last_name">
                      Nom*
                    </label>
                    <input
                        autoComplete="off"
                        className="form-control"
                        placeholder="Dupont"
                        {...register("last_name")}
                    />
                    {errors["last_name"]?.message && (
                        <p className="error">
                          {errors["last_name"] &&
                              (errors["last_name"] as { message: string }).message}
                        </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={5.5} mdOffset={0}>
                    <label className="form-label" htmlFor="mobile">
                      Téléphone*
                    </label>
                    <input
                        autoComplete="off"
                        className="form-control"
                        placeholder="+33"
                        {...register("mobile")}
                    />
                    {errors["mobile"]?.message && (
                        <p className="error">
                          {errors["mobile"] &&
                              (errors["mobile"] as { message: string }).message}
                        </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={5.5} mdOffset={1}>
                    <label className="form-label" htmlFor="city">
                      Ville*
                    </label>
                    <input
                        autoComplete="off"
                        className="form-control"
                        placeholder="Lille"
                        {...register("city")}
                    />

                    {errors["city"]?.message && (
                        <p className="error">
                          {errors["city"] &&
                              (errors["city"] as { message: string }).message}
                        </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className="form-label" htmlFor="email">
                      Email*
                    </label>
                    <input
                        autoComplete="off"
                        className="form-control"
                        placeholder="contact@gmail.com"
                        {...register("email")}
                    />
                    {errors["email"]?.message && (
                        <p className="error">
                          {errors["email"] &&
                              (errors["email"] as { message: string }).message}
                        </p>
                    )}
                  </Grid>
                  <p className="form-title">Préférence de contact</p>
                  <Grid xs={12} md={12}>
                    <label className="form-label" htmlFor="00N68000003PFn9">
                      Date souhaitée du rendez-vous*
                    </label>
                    <Controller
                        control={control}
                        name="00N68000003PFn9"
                        render={({ field }) => (
                            <DatePicker
                                {...register("00N68000003PFn9")}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="00/00/2023"
                                className="form-control"
                                onChange={(date) => field.onChange(date)}
                                selected={field.value}
                                minDate={new Date()}
                                autoComplete="off"
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                            />
                        )}
                    />
                    {errors["00N68000003PFn9"]?.message && (
                        <p className="error">
                          {errors["00N68000003PFn9"] &&
                              (errors["00N68000003PFn9"] as { message: string })
                                  .message}
                        </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className="form-label" htmlFor="00N68000003PFni">
                      Plage horaire souhaitée*
                    </label>
                    <Controller
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                            <Select
                                {...register("00N68000003PFni")}
                                className="form-control form-control-select"
                                options={houres}
                                onBlur={onBlur}
                                onChange={(data: any) => {
                                  onChange(data.value);
                                }}
                                value={value ? { value, label: value } : null}
                                placeholder="00h-00h"
                            />
                        )}
                        name="00N68000003PFni"
                    />

                    {errors["00N68000003PFni"]?.message && (
                        <p className="error">
                          {errors["00N68000003PFni"] &&
                              (errors["00N68000003PFni"] as { message: string })
                                  .message}
                        </p>
                    )}
                  </Grid>
                  <Grid
                      xs={12}
                      md={12}
                      className="form-control-wrapper form-control-wrapper-checkbox"
                  >
                    <input
                        type="checkbox"
                        className="form-control"
                        id="00N68000003PFnx"
                        {...register("00N68000003PFnx")}
                        value="1"
                    />
                    <label htmlFor="00N68000003PFnx" className="tandc">
                      Je reconnais que les champs marqués d’un astérisque sont
                      obligatoires. Chasseurs d’Autos traite les données
                      recueillies pour répondre à votre demande de renseignement.
                      Pour en savoir plus sur la gestion de vos données
                      personnelles et pour exercer vos droits, reportez-vous à la
                      politique de confidentialité.
                    </label>
                    {errors["00N68000003PFnx"]?.message && (
                        <p className="error">
                          {errors["00N68000003PFnx"] &&
                              (errors["00N68000003PFnx"] as { message: string })
                                  .message}
                        </p>
                    )}
                  </Grid>
                </Grid>

                {recaptchaToken && (
                    <div className="form-captch">
                      <ReCAPTCHA
                          className="form-captch"
                          sitekey={recaptchaToken}
                          onChange={onChange}
                      />

                      {captcha.error && (
                          <p className="error error-captch">
                            Le captcha est invalide. Veuillez réessayer.
                          </p>
                      )}
                    </div>
                )}

                <Button text="Contactez-moi" submit dark />
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
  );
}