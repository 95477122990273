import React, { useEffect, useState } from "react";
import Grid from "./grid";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { startLoading, stopLoading } from "../redux/actions/appActions";
import CardBuy from "./cardBuy";
import Icon from "./Icon";
import Button from "./button";
import ListPagination from "./ListPagination";
import { price_format } from "../shared/helpers";
import { fetchImages } from "../shared/APIs";
import FormBuyDirect from "./formBuyDirect";
import moment from "moment";
import ImageGallery from "react-image-gallery";
import dotenv from 'dotenv';


const blockProduct = ({ productData, dispatch }: any) => {
  const [activeImage, setActiveImage] = useState(0);
  const [images, setImages] = useState<any[] | undefined>([]);
  const [buyDirect, setBuyDirect] = useState<null | boolean>(null);

  if (!productData) {
    return <h1>No product data</h1>;
  }

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const dataImages: string[] | undefined = await fetchImages(
          productData.Id
      );
      dispatch(stopLoading());
      if (dataImages) {
        const modifiedImages = dataImages.map((img) => {
          return {
            original: img.toString(),
            thumbnail: img.toString(),
          };
        });
        setImages(modifiedImages);
      }
    })();
  }, []);

  const formattedDateMEC = moment(productData.DateMEC__c, "DD/MM/YYYY");
  const ageInMonths = moment().diff(formattedDateMEC, "months");

  return (
      <Grid container className="block-product">
        <Grid sm={12}>
          <div className="head"></div>
          <Grid container maxWidth="lg">
            <Grid
                sm={12}
                sx={{
                  mt: {
                    md: 7,
                    sm: 4,
                  },
                  mb: {
                    md: 6,
                    sm: 4,
                  },
                }}
            >
              <Link to="/acheter" className="link-back">
                &lt; Retour aux choix des véhicules
              </Link>
            </Grid>
            <Grid md={6} sm={12}>
              <div className="product">
                <div className="left-side">
                  {images && images.length > 0 && <ImageGallery items={images} />}

                  <div className="product-details product-details-mb">
                    <CardBuy
                        icon="auto"
                        type="product"
                        title="Informations"
                        description="du véhicule"
                    />
                    <div className="product-details-content">
                      <div className="car-content">
                        <div className="top">
                          <p className="name">{productData.Marque__c+" "+productData.Modele__c}</p>
                          {productData.Version__c ? <p className="description">{productData.Version__c} </p> : ""}
                        </div>
                        <div className="bottom">
                          {productData.PrixTTC__c && (
                              <p className="price">
                                {productData.PrixTTC__c}
                                <span className="coin">€</span>
                              </p>
                          )}
                          <div className="location">
                            <p> Montant location à partir de </p>
                            <p>
                              {productData.LoyerMensuel__c || "--- "}€/mois
                              <div className="tooltip">
                                <div className="icon">i</div>
                                <div className="details">
                                  Un crédit vous engage et doit être remboursé.
                                  Vérifiez vos capacités de remboursement avant de
                                  vous engager
                                </div>
                              </div>
                            </p>
                          </div>
                        </div>
                        {!buyDirect ? (
                            <div className="actions">
                              {productData?.LoyerMensuel__c &&
                                  ageInMonths < 83 &&
                                  productData?.Energie__c &&
                                  productData?.KmCompteur__c &&
                                  productData?.TypeBoite__c &&
                                  productData?.PrixTTC__c && (
                                      <Button
                                          text="Je la finance en LOA"
                                          dark
                                          to={{
                                            path: "/financement",
                                            data: { state: { productData, images } },
                                          }}
                                      />
                                  )}
                              <Button
                                  text="Je l’achète directement"
                                  outlined
                                  onPress={() => setBuyDirect(true)}
                              />
                            </div>
                        ) : (
                            <FormBuyDirect carId={productData.Id} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="product-details">
                    <CardBuy
                        icon="auto"
                        type="product"
                        title="Informations"
                        description="principales"
                    />
                    <div className="product-details-content">
                      <div className="cards">
                        {productData.KmCompteur__c && (
                            <div className="card-info">
                              <div className="card-wrapper">
                                <Icon icon="road" size="20" />
                                <p className="title">Kilométrage compteur</p>
                                <p className="info">
                                  {productData.KmCompteur__c}km
                                </p>
                              </div>
                            </div>
                        )}
                        {productData.Energie__c && (
                            <div className="card-info">
                              <div className="card-wrapper">
                                <Icon icon="water" size="20" />
                                <p className="title">Type de carburant</p>
                                <p className="info">{productData.Energie__c}</p>
                              </div>
                            </div>
                        )}

                        {productData.TypeBoite__c && (
                            <div className="card-info">
                              <div className="card-wrapper">
                                <Icon icon="box" size="20" />
                                <p className="title">Type de boite</p>
                                <p className="info">{productData.TypeBoite__c}</p>
                              </div>
                            </div>
                        )}

                        {productData.DateMEC__c && (
                            <div className="card-info">
                              <div className="card-wrapper">
                                <Icon icon="calendar" size="20" />
                                <p className="title">Date de mise en circulation</p>
                                <p className="info">{productData.DateMEC__c}</p>
                              </div>
                            </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="product-details">
                    <CardBuy
                        icon="eye"
                        type="product"
                        title="Informations"
                        description="complémentaires"
                    />
                    <div className="product-details-content">
                      <ul className="list-details">
                        {/*
                      <li>
                        <p>
                          <span className="key">Année :</span>
                          <span className="value">value</span>
                        </p>
                      </li>
                      */}
                        {productData.Up_to_date_technical_control__c !==
                            (null || undefined) && (
                                <li>
                                  <p>
                            <span className="key">
                              Contrôle technique à jour ?
                            </span>
                                    <span className="value">
                              {productData.Up_to_date_technical_control__c
                                  ? "Oui"
                                  : "Non"}
                            </span>
                                  </p>
                                </li>
                            )}

                        {productData.Couleur__c && (
                            <li>
                              <p>
                                <span className="key">Couleur extérieure :</span>
                                <span className="value">
                              {productData.Couleur__c}
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.NBPortes__c && (
                            <li>
                              <p>
                                <span className="key">Nombre de portes :</span>
                                <span className="value">
                              {productData.NBPortes__c}
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.NBPlaces__c && (
                            <li>
                              <p>
                                <span className="key">Nombre de places :</span>
                                <span className="value">
                              {productData.NBPlaces__c}
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.Warranty__c && (
                            <li>
                              <p>
                                <span className="key">Garantie</span>
                                <span className="value">
                              {productData.Warranty__c} mois
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.First_hand_declarative__c && (
                            <li>
                              <p>
                            <span className="key">
                              Première main (déclaratif) :
                            </span>
                                {/* API Error Invalid Field */}
                                <span className="value">
                              {productData.First_hand_declarative__c}
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.PuissanceFiscale__c && (
                            <li>
                              <p>
                                <span className="key">Puissance fiscale :</span>
                                <span className="value">
                              {productData.PuissanceFiscale__c}
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.PuissanceKw__c && (
                            <li>
                              <p>
                                <span className="key">Puissance en KW</span>
                                <span className="value">
                              {productData.PuissanceKw__c}
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.PuissanceCH__c && (
                            <li>
                              <p>
                                <span className="key">Puissance moteur (CH din)</span>
                                <span className="value">
                              {productData.PuissanceCH__c}
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.Crit_air__c && (
                            <li>
                              <p>
                                <span className="key">Crit&apos;Air :</span>
                                <span className="value">
                              {productData.Crit_air__c}
                            </span>
                              </p>
                            </li>
                        )}

                        {productData.CO2__c && (
                            <li>
                              <p>
                                <span className="key">Émissions de CO2 :</span>
                                <span className="value">{productData.CO2__c}</span>
                              </p>
                            </li>
                        )}
                      </ul>
                    </div>
                  </div>

                  {productData?.options?.length > 0 &&
                      productData?.equipments?.length > 0 && (
                          <div className="product-details">
                            <CardBuy
                                icon="light"
                                type="product"
                                title="Equipements & options"
                                description="complémentaires"
                            />
                            <div className="product-details-content">
                              {productData.equipments &&
                                  productData.equipments.length > 0 && (
                                      <div className="list">
                                        <h4 className="list-title">Équipements</h4>
                                        {
                                          <ListPagination
                                              items={productData.equipments}
                                              itemsPerPage={15}
                                          />
                                        }
                                      </div>
                                  )}

                              <hr />

                              {productData.options && productData.options.length > 0 && (
                                  <div className="list">
                                    <h4 className="list-title">Options</h4>
                                    <ListPagination
                                        items={productData.options}
                                        itemsPerPage={15}
                                    />
                                  </div>
                              )}
                            </div>
                          </div>
                      )}
                </div>
              </div>
            </Grid>
            <Grid
                className="right-side"
                md={6}
                sm={12}
                sx={{
                  display: {
                    md: "block",
                    sm: "none",
                  },
                  px: {
                    md: 6,
                    sm: 0,
                  },
                  my: {
                    md: 0,
                    sm: 0,
                  },
                }}
            >
              <div className="fixed-wrapper">
                <div className="product-details">
                  <CardBuy
                      icon="auto"
                      type="product"
                      title="Informations"
                      description="du véhicule"
                  />
                  <div className="product-details-content">
                    <div className="car-content">
                      <div className="top">
                        <p className="name">{productData.Marque__c+" "+productData.Modele__c}</p>
                        {productData.Version__c ? <p className="description">{productData.Version__c} </p> : ""}
                      </div>
                      <div className="bottom">
                        {productData.PrixTTC__c && (
                            <p className="price">
                              {price_format(productData.PrixTTC__c)}
                              <span className="coin">€</span>
                            </p>
                        )}

                        <div className="location">
                          <p> Montant location à partir de </p>
                          <p>
                            {productData.LoyerMensuel__c || "--- "}€/mois
                            <div className="tooltip">
                              <div className="icon">i</div>
                              <div className="details">
                                Un crédit vous engage et doit être remboursé.
                                Vérifiez vos capacités de remboursement avant de
                                vous engager
                              </div>
                            </div>
                          </p>
                        </div>
                      </div>

                      {!buyDirect ? (
                          <div className="actions">
                            {productData?.LoyerMensuel__c &&
                                ageInMonths < 83 &&
                                productData?.Energie__c &&
                                productData?.KmCompteur__c &&
                                productData?.TypeBoite__c &&
                                productData?.PrixTTC__c && (
                                    <Button
                                        text="Je la finance en LOA"
                                        dark
                                        to={{
                                          path: "/financement",
                                          data: { state: { productData, images } },
                                        }}
                                    />
                                )}
                            <Button
                                text="Je l’achète directement"
                                outlined
                                onPress={() => setBuyDirect(true)}
                            />
                          </div>
                      ) : (
                          <FormBuyDirect carId={productData.Id} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
};
function mapStateToProps(state: any) {
  return { state };
}
export default connect(mapStateToProps)(blockProduct);