import React, { useEffect, useState } from "react";
import PageWrapper from "../components/PageWrapper";
import BlockProduct from "../components/blockProduct";
import { useLocation } from "react-router-dom";

import Loading from "../components/Loading";

import axiosInstance from "../shared/axiosInstance";
import setAccessToekn from "../shared/setAccessToken";
import { AxiosError } from "axios";
import { isDev } from "../shared/helpers";

export default function ProductPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [productData, setProductData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      const fetchProducts = async () => {
        const searchQuery = `SELECT+Id,Name,Version__c,Modele__c,Marque__c,KmCompteur__c,PrixTTC__c,TypeBoite__c,Energie__c,DateMEC__c,Up_to_date_technical_control__c,NBPortes__c,Couleur__c,NBPlaces__c,Warranty__c,PuissanceFiscale__c,PuissanceKw__c,PuissanceCH__c,Crit_air__c,CO2__c,LoyerMensuel__c,NATCode__c+FROM+Vehicule__c+WHERE+Id+=+'${id}'${

          !isDev ? "+AND+Website_uploading__c+=+true" : ""
        }`;

        try {
          const { data } = await axiosInstance.get(
            `/services/data/v56.0/query?q=${searchQuery}`
          );
          fetchProductsDetails(data.records[0]);
        } catch (err) {
          console.log("Error:", (err as Error).message);
          if ((err as AxiosError)?.response?.status === 401) {
            await setAccessToekn();
            return;
          }
          setIsLoading(false);
        }
      };

      const fetchProductsDetails = async (pData: any) => {
        const searchQuery = `SELECT+Name,EquipmentType__c+FROM+Option_equipements__c+WHERE+Vehicule__c+=+'${id}'`;

        try {
          const { data } = await axiosInstance.get(
            `/services/data/v56.0/query?q=${searchQuery}`
          );
          let equipments = data.records.filter(
            (d) => d.EquipmentType__c === "Équipement de série"
          );

          let options = data.records.filter(
            (d) => d.EquipmentType__c !== "Équipement de série"
          );
          equipments = equipments.map((d) => d.Name);
          options = options.map((d) => d.Name);
          setProductData({ ...pData, equipments, options });
          setIsLoading(false);
        } catch (err) {
          console.log("Error:", (err as Error).message);
          setIsLoading(false);
        }
      };

      fetchProducts();
    }
  }, []);

  if (!id) return <h1>No Id Provided</h1>;

  if (isLoading) return <Loading />;

  return (
    <div className="page-Product">
      <PageWrapper>
        <BlockProduct productData={productData} />
      </PageWrapper>
    </div>
  );
}
