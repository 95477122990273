import axios from "axios";
import { isDev } from "../shared/helpers";

const setAccessToken = async () => {
  try {
    const formData: any = new FormData();
    formData.append("username", `${process.env.REACT_APP_EMAIL}`);
    formData.append("password", `${process.env.REACT_APP_PASSWORD}`);
    formData.append("grant_type", "password");
    formData.append("client_id", `${process.env.REACT_APP_CLIENT_ID}`);
    formData.append("client_secret", `${process.env.REACT_APP_CLIENT_SECRET}`);

    const queryString: string = new URLSearchParams(
      formData as unknown as Record<string, string>
    ).toString();

    const response = await axios.post("/services/oauth2/token?" + queryString);

    const tokenData = response.data;

    // Calculate expiration time
    const issuedAt = parseInt(tokenData.issued_at);

    const expirationDate = new Date(issuedAt + 604800000); // Adding 1 week (in milliseconds)

    // Save token data to a cookie with expiration
    document.cookie =
      "tokenData=" +
      JSON.stringify(tokenData.access_token) +
      "; expires=" +
      expirationDate.toUTCString() +
      "; path=/";
    window.location.reload();
  } catch (error) {
    console.error(error);
  }
};
export default setAccessToken;
