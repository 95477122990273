import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Grid from "./grid";
import Button from "./button";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as yup from "yup";
import { radioUncheck, numberPlateFormat, isDev } from "../shared/helpers";
import ReCAPTCHA from "react-google-recaptcha";

import { yupResolver } from "@hookform/resolvers/yup";

const recaptchaToken = process.env.REACT_APP_RECAPTCHA_TOKEN;

yup.setLocale({
  mixed: {
    required: "Ce champ est obligatoire",
  },
});
const schema = yup.object().shape({
  first_name: yup.string().max(40).required(),
  last_name: yup.string().max(80).required(),
  phone: yup.number().typeError("Doit être un nombre").required(),
  city: yup.string().max(40).required(),
  email: yup
      .string()
      .email("S'il vous plaît, mettez une adresse email valide")
      .max(80)
      .required(),
  "00N68000003PFn9": yup.string().required(), // Date
  "00N68000003PFni": yup.string().required(), // Houre
  "00N68000003PFnx": yup
      .string()
      .oneOf(["1"], "Vous devez accepter les Termes")
      .required("Vous devez accepter les Termes"), // CandT
});

const houres = [
  { value: "8h-10h", label: "8h-10h" },
  { value: "10h-12h", label: "10h-12h" },
  { value: "12h-14h", label: "12h-14h" },
  { value: "14h-16h", label: "14h-16h" },
  { value: "16h-18h", label: "16h-18h" },
  { value: "18h-20h", label: "18h-20h" },
];

export default function FormBuy({
                                  productData,
                                  simulationData,
                                  activeForm,
                                  version,
                                }: {
  productData: any;
  simulationData: any;
  activeForm: number;
  version: number;
}) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("errors:", errors);

  const location = useLocation();

  const formData = location.state?.formData;

  const [captcha, setCaptcha] = useState({ isNotRobot: false, error: false });

  const watchCity = watch("city");

  function onChange(value) {
    if (value) {
      setCaptcha({ isNotRobot: true, error: false });
    }
  }

  const onSubmit = (data: any, e: any) => {
    if (!captcha.isNotRobot) {
      setCaptcha({ isNotRobot: false, error: true });
      return;
    }

    const el1 = document.getElementsByName(
        "00N7Z0000087ieM"
    )[0] as HTMLInputElement;

    if (data.controle == "1") {
      el1.checked = true;
    } else {
      el1.checked = false;
    }
    setTimeout(() => {
      e.target.submit();
    }, 0);
  };

  useEffect(() => {
    if (watchCity) {
      setValue("city", watchCity.toUpperCase());
    }
  }, [watchCity]);

  useEffect(() => {
    // radio uncheck
    radioUncheck(getValues, setValue);
    const subscription = watch((value, { name, type }) =>
        console.log(value, name, type)
    );
    return () => subscription.unsubscribe();
  }, [watch]);

  if (!simulationData || !simulationData.duration) {
    return <p>No simulation data</p>;
  }

  return (
      <div className="block-form">
        <Grid container maxWidth="lg" columnSpacing={8}>
          <Grid xs={12} md={7} mdOffset={0}>
            <h3>Completer le formulaire</h3>
          </Grid>
          <Grid
              xs={12}
              sx={{
                p: {
                  xs: 0,
                  md: 2,
                },
              }}
          >
            <form
                className="form form-sell-2"
                action={`https://${
                    isDev ? "test" : "webto"
                }.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`}
                method="POST"
                onSubmit={handleSubmit(onSubmit)}
            >
              <input
                  name="00N7Z0000087ieM"
                  className="hidden"
                  type="checkbox"
                  value="1"
              />
              <input
                  type="hidden"
                  id="00N68000004VsQC"
                  name="00N68000004VsQC"
                  value={productData.Id}
              />
              <input
                  type="hidden"
                  name="oid"
                  value={isDev ? "00D7Z0000005J1X" : "00D680000029HFM"}
              />

              <input
                  type="hidden"
                  name="retURL"
                  value={
                    isDev
                        ? "https://chasseurs-auto.lagentx.tech?acheterfinancement=true&statut=succes"
                        : "https://chasseursdautos.com?acheterfinancement=true&statut=succes"
                  }
              />

              <select id="lead_source" name="lead_source" hidden>
                <option selected value="Formulaire_site_web"></option>
              </select>
              <input
                  id="utm_source" name="00N68000003PFoM" type="hidden" />
              <input
                  id="utm_medium" name="00N68000003PFoH" type="hidden" />
              <input
                  id="utm_term" name="00N68000003PFoR" type="hidden" />
              <input
                  id="utm_campaign" name="00N68000003PFo7" type="hidden" />
              <input
                  id="utm_content" name="00N68000003PFoC" type="hidden" />

              <Grid container maxWidth="lg" className="form-content" spacing={0}>
                <Grid
                    xs={12}
                    md={5}
                    mdOffset={0}
                    sx={{
                      my: 0,
                    }}
                >
                  <p className="form-title">Informations personnelles</p>
                  <Grid
                      container
                      maxWidth="lg"
                      className="form-controllers"
                      spacing={0}
                  >
                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="first_name">
                        Prénom*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="Jean"
                          {...register("first_name")}
                      />

                      {errors["first_name"]?.message && (
                          <p className="error">
                            {errors["first_name"] &&
                                (errors["first_name"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="last_name">
                        Nom*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="Dupont"
                          {...register("last_name")}
                      />
                      {errors["last_name"]?.message && (
                          <p className="error">
                            {errors["last_name"] &&
                                (errors["last_name"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="phone">
                        Téléphone*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="+33"
                          {...register("phone")}
                      />
                      {errors["phone"]?.message && (
                          <p className="error">
                            {errors["phone"] &&
                                (errors["phone"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="city">
                        Ville*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="Lille"
                          {...register("city")}
                      />

                      {errors["city"]?.message && (
                          <p className="error">
                            {errors["city"] &&
                                (errors["city"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor="email">
                        Email*
                      </label>
                      <input
                          autoComplete="off"
                          className="form-control"
                          placeholder="contact@gmail.com"
                          {...register("email")}
                      />
                      {errors["email"]?.message && (
                          <p className="error">
                            {errors["email"] &&
                                (errors["email"] as { message: string }).message}
                          </p>
                      )}
                    </Grid>
                    <p className="form-title">Préférence de contact</p>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor="00N68000003PFn9">
                        Date souhaitée du rendez-vous*
                      </label>
                      <Controller
                          control={control}
                          name="00N68000003PFn9"
                          render={({ field }) => (
                              <DatePicker
                                  {...register("00N68000003PFn9")}
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="00/00/2023"
                                  className="form-control"
                                  onChange={(date) => field.onChange(date)}
                                  selected={field.value}
                                  minDate={new Date()}
                                  autoComplete="off"
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                              />
                          )}
                      />
                      {errors["00N68000003PFn9"]?.message && (
                          <p className="error">
                            {errors["00N68000003PFn9"] &&
                                (errors["00N68000003PFn9"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor="00N68000003PFni">
                        Plage horaire souhaitée*
                      </label>
                      <Controller
                          control={control}
                          rules={{
                            required: true,
                          }}
                          render={({ field: { onChange, onBlur, value } }) => (
                              <Select
                                  {...register("00N68000003PFni")}
                                  className="form-control form-control-select"
                                  options={houres}
                                  onBlur={onBlur}
                                  onChange={(data: any) => {
                                    onChange(data.value);
                                  }}
                                  value={value ? { value, label: value } : null}
                                  placeholder="00h-00h"
                              />
                          )}
                          name="00N68000003PFni"
                      />

                      {errors["00N68000003PFni"]?.message && (
                          <p className="error">
                            {errors["00N68000003PFni"] &&
                                (errors["00N68000003PFni"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid
                        xs={12}
                        md={12}
                        className="form-control-wrapper form-control-wrapper-checkbox"
                    >
                      <input
                          type="checkbox"
                          className="form-control"
                          id="00N68000003PFnx"
                          value="1"
                          {...register("00N68000003PFnx")}
                      />
                      <label htmlFor="00N68000003PFnx" className="tandc">
                        Je reconnais que les champs marqués d’un astérisque sont
                        obligatoires. Chasseurs d’Autos traite les données
                        recueillies pour répondre à votre demande de
                        renseignement. Pour en savoir plus sur la gestion de vos
                        données personnelles et pour exercer vos droits,
                        reportez-vous à la politique de confidentialité.
                      </label>
                      {errors["00N68000003PFnx"]?.message && (
                          <p className="error">
                            {errors["00N68000003PFnx"] &&
                                (errors["00N68000003PFnx"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={12} md={5} mdOffset={2}>
                  <p className="form-title">Le véhicule</p>
                  <Grid
                      container
                      maxWidth="lg"
                      className="form-controllers"
                      spacing={0}
                  >
                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="00N68000003PFnT">
                        Marque*
                      </label>
                      <Controller
                          name="00N68000003PFnT"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000003PFnT")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Marque"
                                  onChange={field.onChange}
                                  value={productData.Marque__c}
                              />
                          )}
                      />
                      {errors["00N68000003PFnT"]?.message && (
                          <p className="error">
                            {errors["00N68000003PFnT"] &&
                                (errors["00N68000003PFnT"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="00N68000003PFnY">
                        Modèle*
                      </label>
                      <Controller
                          name="00N68000003PFnY"
                          control={control}
                          render={({ field }) => (
                              <input
                                  {...register("00N68000003PFnY")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Modèle"
                                  onChange={field.onChange}
                                  value={productData.Modele__c}
                              />
                          )}
                      />
                      {errors["00N68000003PFnY"]?.message && (
                          <p className="error">
                            {errors["00N68000003PFnY"] &&
                                (errors["00N68000003PFnY"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>

                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="00N68000004VsPO">
                        Apport*
                      </label>
                      <Controller
                          name="00N68000004VsPO"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsPO")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Apport"
                                  onChange={field.onChange}
                                  value={simulationData.deposit}
                              />
                          )}
                      />
                      {errors["00N68000004VsPO"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsPO"] &&
                                (errors["00N68000004VsPO"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="00N68000004VsPT">
                        Durée*
                      </label>
                      <Controller
                          name="00N68000004VsPT"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsPT")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Durée"
                                  onChange={field.onChange}
                                  value={simulationData.duration}
                              />
                          )}
                      />
                      {errors["00N68000004VsPT"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsPT"] &&
                                (errors["00N68000004VsPT"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>

                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="00N68000003PFnO">
                        Kilométrage*
                      </label>
                      <Controller
                          name="00N68000003PFnO"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000003PFnO")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Kilométrage"
                                  onChange={field.onChange}
                                  value={simulationData.credit_mile_age}
                              />
                          )}
                      />
                      {errors["00N68000003PFnO"]?.message && (
                          <p className="error">
                            {errors["00N68000003PFnO"] &&
                                (errors["00N68000003PFnO"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="00N68000004VsPY">
                        Formule*
                      </label>
                      <Controller
                          name="00N68000004VsPY"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsPY")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Formule"
                                  onChange={field.onChange}
                                  value={
                                    activeForm === 1
                                        ? "Pack Simply Sécurité"
                                        : activeForm === 2
                                            ? "Pack Simply Tranquillité"
                                            : ""
                                  }
                              />
                          )}
                      />
                      {errors["00N68000004VsPY"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsPY"] &&
                                (errors["00N68000004VsPY"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="00N68000004VsPd">
                        Option*
                      </label>
                      <Controller
                          name="00N68000004VsPd"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsPd")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Options"
                                  onChange={field.onChange}
                                  value={
                                    version === 2
                                        ? "Extension de Garantie 12 mois"
                                        : version === 3
                                            ? "Pack Excellence"
                                            : ""
                                  }
                              />
                          )}
                      />
                      {errors["00N68000004VsPd"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsPd"] &&
                                (errors["00N68000004VsPd"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="00N68000004VsPi">
                        Montant emprunté*
                      </label>
                      <Controller
                          name="00N68000004VsPi"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsPi")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Montant emprunté"
                                  onChange={field.onChange}
                                  value={simulationData.amount.toFixed(2)}
                              />
                          )}
                      />
                      {errors["00N68000004VsPi"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsPi"] &&
                                (errors["00N68000004VsPi"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={0}>
                      <label className="form-label" htmlFor="00N68000004VsPn">
                        Premier loyer*
                      </label>
                      <Controller
                          name="00N68000004VsPn"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsPn")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Premier loyer"
                                  onChange={field.onChange}
                                  value={simulationData.monthly_payment.toFixed(2)}
                              />
                          )}
                      />
                      {errors["00N68000004VsPn"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsPn"] &&
                                (errors["00N68000004VsPn"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={5.5} mdOffset={1}>
                      <label className="form-label" htmlFor="00N68000004VsPs">
                        Loyers suivants*
                      </label>
                      <Controller
                          name="00N68000004VsPs"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsPs")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Loyers Suivants"
                                  onChange={field.onChange}
                                  value={simulationData.monthly_payment.toFixed(2)}
                              />
                          )}
                      />
                      {errors["00N68000004VsPs"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsPs"] &&
                                (errors["00N68000004VsPs"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor="00N68000004VsPx">
                        Montant total dû avec option d&apos;achat finale*
                      </label>
                      <Controller
                          name="00N68000004VsPx"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsPx")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Montant total dû avec option d'achat finale"
                                  onChange={field.onChange}
                                  value={simulationData.total_amount.toFixed(2)}
                              />
                          )}
                      />
                      {errors["00N68000004VsPx"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsPx"] &&
                                (errors["00N68000004VsPx"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    <Grid xs={12} md={12}>
                      <label className="form-label" htmlFor="00N68000004VsQ2">
                        Montant total dû sans option d&apos;achat finale*
                      </label>
                      <Controller
                          name="00N68000004VsQ2"
                          control={control}
                          render={({ field }) => (
                              <input
                                  readOnly
                                  {...register("00N68000004VsQ2")}
                                  autoComplete="off"
                                  className="form-control"
                                  placeholder="Montant total dû avec option d'achat finale"
                                  onChange={field.onChange}
                                  value={simulationData.credit_cost.toFixed(2)}
                              />
                          )}
                      />
                      {errors["00N68000004VsQ2"]?.message && (
                          <p className="error">
                            {errors["00N68000004VsQ2"] &&
                                (errors["00N68000004VsQ2"] as { message: string })
                                    .message}
                          </p>
                      )}
                    </Grid>
                    {recaptchaToken && (
                        <div className="form-captch">
                          <ReCAPTCHA
                              className="form-captch"
                              sitekey={recaptchaToken}
                              onChange={onChange}
                          />

                          {captcha.error && (
                              <p className="error error-captch">
                                Le captcha est invalide. Veuillez réessayer.
                              </p>
                          )}
                        </div>
                    )}

                    <div className="form-info">
                      <div className="icon">i</div>
                      <div className="details">
                        Un crédit vous engage et doit être remboursé. Vérifiez vos
                        capacités de remboursement avant de vous engager
                      </div>
                    </div>

                    <Button text="Envoyer" submit dark />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
  );
}