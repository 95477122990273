import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Grid from "../components/grid";


export default function TermsConditionsSale() {
  return (
      <PageWrapper>
          <Head>
              <b>CONDITIONS GENERALES DE VENTE</b>
          </Head>
          <Grid container maxWidth="lg">
              <div className="legalWrapper">
                  <div>
                      <p>Les présentes conditions générales de vente (CGV) s&apos;appliquent à l&apos;ensemble des services et ventes conclus entre CHASSEURS D&apos;AUTOS, société par actions simplifiée, au capital de 10.000€, dont le siège social est situé 8F rue Jeanne Barret, 21000 Dijon, et immatriculée au RCS de Dijon sous le numéro : 443171558 (le « <strong>Vendeur</strong> ») à des clients (le « <strong>Vendeur</strong> »).</p>
                      <p>Elles ont été portées à la connaissance du client préalablement à la conclusion de toute relation contractuelle.</p>
                      <p>Toute commande emporte acceptation sans réserve des présentes CGV.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 1- Définition</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Client : tout Client Particulier ainsi que tout autre client.</p>
                      <p>Client Particulier :  tout client qui agit à des fins qui n&apos;entrent pas dans le cadre de son activité commerciale, industrielle, artisanale ou libérale.</p>
                      <p>Contrat à distance :  tout contrat conclu entre CHASSEURS D&apos;AUTOS et un Client, à distance CHASSEURS D’AUTOS, sans la présence physique simultanée de CHASSEURS D’AUTOS et du Client, par le recours exclusif à une ou plusieurs techniques de communication à distance jusqu&apos;à la conclus ion du contrat.</p>
                      <p>Véhicule : Véhicules d&apos;occasion.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 2 – Objet</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>CHASSEURS D’AUTOS propose un service d’accompagnement dans la vente de véhicules ainsi que dans l’acquisition de véhicules avec option d’achat (LOA).</p>
                      <p>Afin de pouvoir proposer son véhicule à la vente, le Client s’engage à renseigner le formulaire destiné à cet effet. CHAUSSEURS D’AUTOS prendra rendez-vous avec le Client, qui s’engage à préparer les documents clés nécessaires, à savoir les éléments relatifs à l’entretien, au contrôle technique et la carte grise du véhicule. CHAUSSEURS D’AUTOS s’engage à diffuser l’annonce du véhicule sur les principaux sites à forte audience automobile et à gérer les prises de contact ainsi que les visites avec les potentiels acheteurs. Lors de la vente, les fonds sont sécurisés par un partenaire tiers (CashSentinel).</p>
                      <p>Afin de pouvoir acquérir un véhicule, le Client a la possibilité d’utiliser le moteur de recherche avec les critères souhaités.</p>
                      <p className="p-left">Si CHASSEURS D’AUTOS ne parvient pas à obtenir et vérifier l’ensemble de ces éléments, aucune proposition commerciale ne sera transmise au Client.</p>
                      <p className="p-left">En cas de dossier complet, CHASSEURS D’AUTOS proposera au client une offre commerciale comprenant une LOA.</p>
                      <p className="p-left">Le Client s’engage à payer les loyers de sa LOA.</p>
                      <p className="p-left">Le Véhicule est décrit sur le Bon de Commande adressé par CHASSEURS D’AUTOS au Client. Ce Véhicule a été choisi préalablement par le Client.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 3 - Acceptation des Conditions Générales de Vente (CGV)</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Tout Bon de Commande adressé par CHASSEURS D’AUTOS au Client, retourné signé par le Client à CHASSEURS D’AUTOS emporte de sa part son adhésion pleine et entière aux présentes CGV.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 4 - Prix</h3>
                      <hr/>
                  </div>
                  <div>
                      <p><span>Cookies pouvant être déposés sans consentement</span></p>
                      <p>Le prix de la location du Véhicule tel que figurant sur le Bon de Commande de CHASSEURS D&apos;AUTOS adressé au Client s&apos;entend TTC et comprend, sans que cette liste ne soit limitative:
                          <br/>-le prix du Véhicule décrit avec ses équipements de série et options<br/>
                          -le coût de la carte grise définitive dépend de la puissance fiscale du Véhicule, du niveau d&apos;émission de C02 du Véhicule et du lieu de résidence du Client. Son montant sera à acquitter par le Client, en sus du Prix et autres frais dus par le Client</p>
                      <p>Les honoraires demandés par CHASSEURS D&apos;AUTOS pour l&apos;accompagnement dans le cadre de la vente d&apos;un véhicule sont mentionnés sur le site CHASSEURS D&apos;AUTOS.</p>
                      <p><span>Frais de formalités</span></p>
                      <p>Les véhicules d&apos;occasions ne bénéficient pas du bonus écologique. Les mesures gouvernementales s&apos;appliquant sur l&apos;année en cours uniquement, <strong>CHASSEURS D&apos;AUTOS</strong> n&apos;est pas en mesure de savoir à l&apos;avance si ces dispositifs seront reconduits ni les modalités exactes de ces dispositifs dans le cadre d&apos;une éventuelle reconduction. <strong>CHASSEURS D&apos;AUTOS</strong> ne pourra être tenu pour responsable de la suppression ou de la modification de ces dispositifs, notamment dans le cadre des véhicules loués sur une année et livrés sur l&apos;année suivante.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 5 - Modalités de paiement</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Lors de l&apos;acceptation du Bon de Commande, le Client s&apos;engage à s&apos;acquitter au profit de CHASSEURS D’AUTOS, des sommes convenues selon les modalités stipulées au Bon de Commande ou sur le site CHASSEURS D’AUTOS.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 6 -Achat à crédit, location</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Chaque fois que le prix du véhicule commandé est acquitté à l’aide de LOA, mention en est portée sur le bon de commande. La vente sera résolue de plein droit sans indemnité (en cas d&apos;usage privé) :</p>
                      <p className="p-left">- si le prêteur n&apos;a pas informé le vendeur de l&apos;attribution du crédit dans un délai de 7 jours â compter de l&apos;acceptation de l&apos;offre</p>
                      <p className="p-left">- si le client emprunteur exerce son droit de rétractation dans un délai de 14 jours , à compter de l&apos;acceptation de l&apos;offre (réduit à la date de livraison sans pouvoir être inférieur à 3 jours si le client a expressément demandé la livraison anticipée).</p>
                      <p className="p-left">- En cas de demande de livraison Immédiate, le client devra écrire de sa main sur le bon de commande «je demande à être livré immédiatement ». Le délai légal de rétractation du contrat de crédit arrive dès lors à échéance à la date de la livraison sans pouvoir être inférieur à trois jours, ni supérieur à 14 jours suivant la signature du client. Celui-ci est tenu par son contrat de vente principal dès le 4eme jour suivant sa signature.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 7- Contrats à distance et hors établissement</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>En cas de contrat à distance ou de contrat hors établissement, conclu avec un consommateur au sens de l&apos;article préliminaire du code de la consommation- ou en cas de contrat hors établissement conclu avec un professionnel dont le nombre de salariés est Inférieur ou égal  5, dès lors que l&apos;objet de ce contrat n&apos;entre pas dans le champ de son activité principale -le client pourra annuler la commande , pendant un délai de 14 jours à compter de la réception du véhicule en utilisant notamment le formulaire ci-joint ou tout autre déclaration, dénuée d&apos;ambiguïté, exprimant sa volonté de se rétracter.</p>
                      <p>Il est expressément précisé que, conformément à l&apos;article L121-21-8 du code de la consommation, l&apos;immatriculation du véhicule par le vendeur et les services exécutés à la demande du client ne bénéficient pas du droit de rétractation.</p>
                      <p>En cas d&apos;exercice de ce droit de rétractation, le client devra restituer le véhicule à ses frais dans son état d&apos;origine, au vendeur dans les plus brefs délais et au plus tard dans les 14 jours.</p>
                      <p>Le client est informé que dans ce cas, il est responsable financièrement de la dépréciation du véhicule résultant de manipulations autres que celles nécessaires pour établir la nature, les caractéristiques et le bon fonctionnement de celui-ci.
                          En conséquence, lors de la restitution par le client, le vendeur remboursera au client les sommes versées diminuées:</p>
                      <p className="p-left">- des frais de remise en état nécessaires;</p>
                      <p className="p-left">- des Indemnités légalement acceptables dont notamment une indemnité de 10€ du kilométrage parcouru au-delà d&apos;un forfait de 50 kilomètres réputés nécessaires à l&apos;établissement de la nature, des caractéristiques et du bon fonctionnement du véhicule;</p>
                      <p className="p-left">- et des sommes versées au titre des services exécutés par le vendeur notamment des frais d&apos;immatriculation du véhicule et taxes associées (ex : malus) Dans le cadre d&apos;un central hors établissement, pendant un délai de 7 jours à compter de la signature du présent bon de commande, le vendeur ne pourra recevoir aucun paiement ou acompte de la part du client.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 8 - Livraison</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>En cas de vente de son véhicule grâce à CHASSEURS D’AUTOS, la livraison est assurée par le Client. En cas d’acquisition par le Client de son véhicule grâce à CHASSEURS D&apos;AUTOS, les modalités de livraison seront précisées au Client.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 9 • Modalités de mise à disposition</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Les modalités seront communiquées au Client par CHASSEURS D&apos;AUTOS.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 10 • Droit de rétractation</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Pour les Contrats à distance ou à la suite d&apos;un démarchage téléphonique, le client dispose d&apos;un délai de rétractation de quatorze (14) jours ouvrés à compter de la conclusion de la relation contractuelle.</p>
                      <p>Le Client pourra exercer son droit de rétractation en adressant à CHASSEURS D’AUTOS un courrier exprimant sans ambiguïté sa volonté de se rétracter et contenant les éléments permettant de l&apos;identifier (nom, prénom, adresse numéro du bon de Commande). Il pourra utiliser à cette fin le formulaire de rétractation joint aux présentes conditions générales de vente, qu&apos;il adressera à l&apos;adresse suivante: « CHASSEURS D’AUTOS– 8F rue Jeanne Barret, 21000 Dijon ». Le client devra être en mesure de prouver qu&apos;il a exercé son droit de rétractation. Le client ne pourra exercer son droit de rétractation qu&apos;à la condition que l&apos;ensemble des documents, notamment légaux, accompagnant le Véhicule soient restitués au plus tard concomitamment à la remise du Véhicule.
                          Les frais résultants du retour du Véhicule sont à la charge du client.
                      </p>
                      <p>L&apos;exercice du droit de rétractation annule le contrat de location avec option d&apos;achat. Aucune indemnité n&apos;est due.</p>
                      <p>Le Client devra restituer le Véhicule au point de vente livreur ou à l&apos;agence de CHASSEURS D’AUTOS sans retard et au plus tard dans les quatorze (14) jours ouvrés suivant la communication de sa décision de se rétracter.</p>
                      <p>Dès lors que le client aura exercé son droit de rétractation dars le délai imparti et selon les conditions ci avant décrites, CHASSEURS D&apos;AUTOS s&apos;engage, dès que le Véhicule lui aura été restitué ou dès que le client aura fourni la preuve de l&apos;expédition du Véhicule, à rembourser le client, des sommes versées, dans un délai de 7 jours.</p>
                      <p>En toute hypothèse, les frais de remise en état du Véhicule restitué- si celui-ci a été endommagé et/ ou accidenté -, seront à la charge du Client.</p>
                      <p>En outre, si le nombre de kilomètres parcourus depuis la livraison excède 50 km, le client devra indemniser CHASSEURS D’AUTOS, pour chaque kilomètre parcouru au-delà dudit seuil de 50 km, à hauteur de 10 euros TTC par kilomètre.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 11 -Véhicules</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Les Véhicules proposés par CHASSEURS D’AUTOS sont ceux proposés sur le site CHASSEURS D’AUTOS. Le Client a également la possibilité de proposer son propre véhicule à la vente sur le site de CHASSEURS D&apos;AUTOS.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 12 • Garantie légale de conformité</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>CHASSEURS D&apos;AUTOS rappelle toutefois que l’Acheteur bénéficie des garanties légales de non-conformité ainsi qu’à raison des vices cachés de la chose vendue, en ce compris les défauts de conformité résultant de l&apos;emballage des Produits commandés sur le Site.</p>
                      <p>Si l&apos;Acheteur constate que le Produit qui lui a été livré présente un défaut, un défaut de conformité ou est endommagé, il doit en informer CHASSEURS D&apos;AUTOS aux coordonnées mentionnées aux présentes, en lui indiquant la nature du défaut, de la non-conformité ou du dommage constaté et en lui envoyant tout justificatif utile, notamment sous la forme de photographie(s).</p>
                      <p>CHASSEURS D&apos;AUTOS organisera avec le transporteur de son choix les modalités du retour, dont elle informera l&apos;Acheteur par tout moyen utile. CHASSEURS D&apos;AUTOS supportera les frais de ce retour.</p>
                      <p>Les Produits doivent impérativement être retournés à CHASSEURS D&apos;AUTOS.</p>
                      <p>Les retours des Produits ne respectant pas les modalités décrites ci-dessus ne pourront pas être pris en compte.</p>
                      <p>CHASSEURS D’AUTOS procédera aux vérifications nécessaires et proposera à l’Acheteur le remplacement du Produit dans la mesure du possible. Si le remplacement du Produit est impossible, CHASSEURS D’AUTOS remboursera à l’Acheteur l’intégralité du prix payé pour le Produit ainsi que les frais de livraison correspondants, par tout moyen utile, dans les meilleurs délais et au plus tard dans les 14 (quatorze) jours suivant la date à laquelle CHASSEURS D&apos;AUTOS l&apos;aura informé de l&apos;impossibilité de remplacer le Produit.</p>
                      <p>Les textes légaux relatifs aux garanties légales sont reproduits en Annexe 1 des présentes.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article - 14 ·Garantie commerciale</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Tous les Véhicules sont garantis:</p>
                      <p>Par la garantie constructeur, lorsque celle-ci est encore valide, c&apos;est-à-dire dans un délai de deux ans ou plus, courant à compter de la première immatriculation du Véhicule.</p>
                      <p>Le contenu, l&apos;étendue et les modalités de mises en œuvre de la garantie sont précisées soit dans le carnet de garantie du constructeur fourni avec les Véhicules livrés par CHASSEURS D&apos;AUTOS soit sur le site internet du constructeur ou dans son réseau.</p>
                      <p>Le Client est informé s&apos;il bénéficie en outre des actions de rappel lors des opérations de sécurité chez le concessionnaire de la marque le plus proche de chez lui.</p>
                      <p>Chaque acquéreur bénéficie également d’une garantie commerciale dont les détails sont précisés sur le site CHASSEURS D&apos;AUTOS.</p>
                      <p>En aucun cas le Client ne pourra prétendre à un recours contre la société CHASSEURS D’AUTOS en cas de mauvais usage ou de non-respect des instructions du constructeur dans l&apos;application de la garantie.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 15 - Assurance</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Le Client s&apos;engage à assurer son Véhicule auprès de son assureur à compter du jour de la livraison.</p>
                      <p>CHASSEURS D&apos;AUTOS se dégage de toute responsabilité en cas de sinistre intervenant après la livraison.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 16 ·Réserve de propriété</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Le véhicule ne devient la propriété du client qu&apos;après complet paiement du prix et des accessoires. Cette clause de réserve de propriété a été portée à la connaissance du client lors de la signature du Bon de Commande.</p>
                      <p>En cas de vente du véhicule du Client, le transfert de propriété se réalise au complet paiement du prix et des accessoires par le biais du service CashSentinel.</p>

                  </div>
                  <div className="w-content">
                      <h3>Article 17· Cas de force majeure</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Les obligations des parties seront suspendues de plein droit et sans formalité́ et leur responsabilité́ dégagée en cas de survenance d’un cas de force majeure entendu comme tout évènement échappant au contrôle d’une partie et qui ne pouvait raisonnablement être prévu lors de la conclusion à la date de la commande et dont les effets ne peuvent être évités par des mesures appropriées et ce, conformément à l&apos;article 1218 du code civil. </p>
                      <p>Sont notamment considérés comme des cas de force majeure : la guerre, l&apos;émeute, une révolution, la grève, une épidémie mondiale, l&apos;incendie ou l&apos;explosion, les catastrophes naturelles et les conditions météorologiques exceptionnelles. Cette suspension ne s&apos;applique cependant pas aux obligations de paiement.</p>
                      <p>La partie touchée par ces circonstances le notifiera, immédiatement, par LRAR ou tout autre coursier de renommée internationale, à l&apos;autre partie et elle mettra tous les moyens en œuvre pour reprendre, dès que possible, l&apos;exécution de ses obligations. Après quatre (4) semaines d&apos;interruption d&apos;exécution, chaque Partie peut résilier de plein droit l&apos;Offre, sans indemnité́, par notification écrite envoyée par LRAR ou tout autre coursier de renommée internationale, avec effet à la date d&apos;envoi de cette notification.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 18 - Médiation</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Il est également rappelé que tout consommateur a le droit de recourir gratuitement à un médiateur de la consommation en vue de la résolution amiable du litige qui l&apos;oppose à un professionnel. A cet effet, CHASSEURS D&apos;AUTOS garantit à l&apos;Acheteur le recours effectif à un dispositif de médiation de la consommation.</p>
                      <p>Médiation des litiges de consommation : Conformément aux dispositions du Code de la consommation concernant le règlement amiable des litiges, CHASSEURS D&apos;AUTOS adhère au Service du Médiateur du conseil national des professions de l&apos;automobile (CNPA) </p>
                      <p>En cas de litige, vous pouvez déposer votre réclamation par email: mediateur@mediateur-cnpa.fr ou par voie postale en écrivant à 43 Bis route de Vaugirard – cs 8001692197 Meudon Cedex</p>
                      <p>Vous pouvez recourir au service de médiation pour les litiges de consommation liés à une commande effectuée sur internet.</p>
                      <p>Il est enfin rappelé que la médiation n&apos;est pas obligatoire mais uniquement proposée afin de résoudre les litiges en évitant un recours à la justice. En cas d&apos;échec de cette procédure de médiation ou si l&apos;Acheteur souhaite saisir une juridiction, les règles du code de procédure civile s&apos;appliqueront.  </p>
                      <p>L&apos;Acheteur peut également entrer en contact avec la plateforme de résolution des litiges mise en ligne par la Commission Européenne à l&apos;adresse suivante : http://ec.europa.eu/consumers/odr/.</p>
                  </div>
                  <div className="w-content">
                      <h3>Article 19 - DONNEES A CARACTERE PERSONNEL, NEWSLETTER ET LISTE D&apos;OPPOSITION AU DEMARCHAGE TELEPHONIQUE</h3>
                      <hr/>
                  </div>
                  <div>
                      <p><span>Données à caractère personnel</span></p>
                      <p>CHASSEURS D’AUTOS pratique une politique de protection des données personnelles.</p>
                      <p>CHASSEURS D’AUTOS comprend que la protection des données et de la vie privée soit un enjeu pour le Client. CHASSEURS D’AUTOS s’engage, conformément à la réglementation RGPD, à respecter votre vie privée et à protéger vos données à caractère personnel, c’est à dire susceptible de vous identifier directement ou indirectement en tant que personne. </p>
                      <p>Dans le cadre de la Commande, CHASSEURS D’AUTOS a vocation à récolter des données personnelles du Client. CHASSEURS D’AUTOS s’engage à protéger les données à caractère personnel.</p>
                      <p>Les fichiers comportant des données à caractère personnel nécessaires pour la Commande sont notamment conservés sur les serveurs. Ce prestataire assure être en conformité avec les prescriptions du règlement général sur la protection des données (RGPD). CHASSEURS D’AUTOS ne communique pas et ne fait pas commerce des données personnelles des Clients.</p>
                      <p>Le Client consent expressément à la collecte et au traitement de ses données à caractère personnel nécessaires pour effectuer les Commandes.</p>
                      <p>Les données personnelles récoltées par CHASSEURS D’AUTOS ont pour objet de permettre la réalisation de la Commande. Les différentes données à caractère personnelles ne seront pas conservées plus longtemps que nécessaire aux fins pour lesquelles elles ont été récoltées, y compris au regard du respect des obligations légales ou fiscales.</p>
                      <p>Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978, telle que modifiée par la loi n°2004-801 du 6 août 2004 dite « Informatique et Libertés », et au règlement général sur la protection des données (RGPD), sous réserve de justifier de votre identité, tout Acheteur, quelle que soit sa nationalité, dispose d’un droit d’accès, de modification et de suppression de ses données à caractère personnel. Chaque Client est également en droit de solliciter une limitation du traitement de ses données et dispose, par ailleurs, d’un droit à la portabilité des données ainsi que d’un droit d’opposition au traitement des données à caractère personnel le concernant.</p>
                      <p>En tout état de cause, tout Acheteur a le droit de faire toute réclamation auprès de la CNIL.</p>
                      <p><span>Newsletter</span></p>
                      <p>En cochant la case prévue à cet effet ou en donnant expressément son accord à cette fin, le Client accepte que CHASSEURS D’AUTOS puisse lui faire parvenir, à une fréquence et sous une forme déterminée par elle, une newsletter (lettre d’information) pouvant contenir des informations relatives à son activité.</p>
                      <p><span>Liste d’opposition au démarchage téléphonique</span></p>
                      <p>Vous avez la possibilité de vous inscrire gratuitement sur une liste d’opposition au démarchage téléphonique BLOCTEL (<a href="www.bloctel.gouv.fr">www.bloctel.gouv.fr</a>) afin de ne plus être démarché téléphoniquement par un professionnel avec lequel vous n’avez pas de relation contractuelle en cours, conformément à la loi n°2014-344 du 17 mars 2014 relative à la consommation.</p>
                      <p>Tout consommateur a la possibilité de s’inscrire gratuitement sur cette liste sur le site <a href="https://conso.bloctel.fr/index.php/inscription.php">https://conso.bloctel.fr/index.php/inscription.php</a></p>
                  </div>
                  <div className="w-content">
                      <h3>Article 20 • Droit applicable et Règlement des litiges</h3>
                      <hr/>
                  </div>
                  <div>
                      <p>Les présentes conditions générales de vente sont régies par le droit français.</p>
                      <p>Le Service Client est à la disposition du client pour toute réclamation et mettra tout en œuvre pour y répondre.</p>
                      <p>Les coordonnées de CHASSEURS D’AUTOS sont : CHASSEURS D’AUTOS, Service Client, 8F rue Jeanne Barret, 21000 Dijon.</p>
                      <p>Le client et CHASSEURS D’AUTOS s&apos;engagent à faire leurs meilleurs efforts pour régler à l&apos;amiable tout différent né de la formation, l&apos;interprétation et l&apos;exécution du contrat.</p>
                      <p><strong>À défaut de résolution amiable, tout litige relatif à l&apos;interprétation et à l&apos;exécution des CGV sera porté devant le Tribunal de Commerce de Dijon, sauf règles de procédure impératives contraires.</strong></p>
                  </div>
                  <div>
                      <h3>ANNEXE 1 - TEXTES LEGAUX RELATIFS AUX GARANTIES</h3>
                      <hr/>
                  </div>
                  <div className="border">
                      <div>
                          <h3>Article L. 217-4 du Code de la consommation</h3>
                          <p>Le vendeur est tenu de livrer un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance. Il répond également des défauts de conformité résultant de l’emballage, des instructions de montage ou de l’installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité.</p>
                          <h3>Article L. 217-5 du Code de la consommation</h3>
                          <p>S’il est propre à l’usage habituellement attendu d’un bien semblable et, le cas échéant :</p>
                          <p className="p-left">– s’il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l’acheteur sous forme d’échantillon ou de modèle ;</p>
                          <p className="p-left">– s’il présente les qualités qu’un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l’étiquetage ;</p>
                          <p>Ou s’il présente les caractéristiques définies d’un commun accord par les parties ou est propre à tout usage spécial recherché par l’acheteur, porté à la connaissance du vendeur et que ce dernier a accepté.</p>
                          <h3>Article L. 217-7 Code de la consommation</h3>
                          <p>Les défauts de conformité qui apparaissent dans un délai de vingt-quatre mois à partir de la délivrance du bien sont présumés exister au moment de la délivrance, sauf preuve contraire. Pour les biens vendus d’occasion, ce délai est fixé à six mois. Le vendeur peut combattre cette présomption si celle-ci n’est pas compatible avec la nature du bien ou le défaut de conformité invoqué.</p>
                          <h3>Article L. 217-8 Code de la consommation</h3>
                          <p>L’acheteur est en droit d’exiger la conformité du bien au contrat. Il ne peut cependant contester la conformité en invoquant un défaut qu’il connaissait ou ne pouvait ignorer lorsqu’il a contracté. Il en va de même lorsque le défaut a son origine dans les matériaux qu’il a lui-même fournis.</p>
                          <h3>Article L. 217-9 Code consommation</h3>
                          <p>En cas de défaut de conformité, l’acheteur choisit entre la réparation et le remplacement du bien. Toutefois, le vendeur peut ne pas procéder selon le choix de l’acheteur si ce choix entraîne un coût manifestement disproportionné au regard de l’autre modalité, compte tenu de la valeur du bien ou de l’importance du défaut. Il est alors tenu de procéder, sauf impossibilité, selon la modalité non choisie par l’acheteur.</p>
                          <h3>Article L. 217-10 Code de la consommation</h3>
                          <p>Si la réparation et le remplacement du bien sont impossibles, l’acheteur peut rendre le bien et se faire restituer le prix ou garder le bien et se faire rendre une partie du prix. La même faculté lui est ouverte :</p>
                          <p className="p-left">1° Si la solution demandée, proposée ou convenue en application de l’article L. 217-9 ne peut être mise en œuvre dans le délai d’un mois suivant la réclamation de l’acheteur ;</p>
                          <p className="p-left">2° Ou si cette solution ne peut l’être sans inconvénient majeur pour celui-ci compte tenu de la nature du bien et de l’usage qu’il recherche.</p>
                          <p> La résolution de la vente ne peut toutefois être prononcée si le défaut de conformité est mineur. »</p>
                          <h3>Article L.217-11 du Code de la consommation</h3>
                          <p>L’application des dispositions des articles L. 217-9 et L. 217-10 a lieu sans aucun frais pour l’acheteur. Ces mêmes dispositions ne font pas obstacle à l’allocation de dommages et intérêts.</p>
                          <h3>Article L. 217-12 du Code de la consommation</h3>
                          <p>L’action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien.</p>
                          <h3>Article L. 217-16 du Code de la consommation</h3>
                          <p>Lorsque l’acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a été consentie lors de l’acquisition ou de la réparation d’un bien meuble, une remise en état couverte par la garantie, toute période d’immobilisation d’au moins sept jours vient s’ajouter à la durée de la garantie qui restait à courir. Cette période court à compter de la demande d’intervention de l’acheteur ou de la mise à disposition pour réparation du bien en cause, si cette mise à disposition est postérieure à la demande d’intervention.</p>
                          <h3>Article 1641 du Code civil</h3>
                          <p>Le vendeur est tenu de la garantie à raison des défauts cachés de la chose vendue qui la rendent impropre à l’usage auquel on la destine, ou qui diminuent tellement cet usage, que l’acheteur ne l’aurait pas acquise, ou n’en aurait donné qu’un moindre prix, s’il les avait connus.</p>
                          <h3>Article 1643 du Code civil</h3>
                          <p>Il est tenu des vices cachés, quand même il ne les aurait pas connus, à moins que, dans ce cas, il n’ait stipulé qu’il ne sera obligé à aucune garantie.</p>
                          <h3>Article 1644 du Code civil</h3>
                          <p>Dans le cas des articles 1641 et 1643, l’acheteur a le choix de rendre la chose et de se faire restituer le prix, ou de garder la chose et de se faire rendre une partie du prix.</p>
                          <h3>Article 1648 alinéa 1er du Code civil</h3>
                          <p>L’action résultant des vices rédhibitoires doit être intentée par l’acquéreur dans un délai de deux ans à compter de la découverte du vice.</p>
                      </div>
                  </div>
                  <div>
                      <p></p>
                      <h3>ANNEXE 2 – FORMULAIRE DE RETRACTATION</h3>
                  </div>
                  <div className="border">
                      <div>
                          <center><p><strong>FORMULAIRE DE RETRACTATION</strong></p></center>
                          <div className="w-content">
                              <h3>Service retour</h3>
                              <hr/>
                          </div>
                          <p>Je vous notifie par la présente, ma rétractation du contrat portant sur le service/produit ci-après mentionné :</p>
                          <p>Commandé le :</p>
                          <p>Numéro de commande :</p>
                          <p>Nom du client :</p>
                          <p>Adresse du client :</p>
                          <p>Signature du client :</p>
                          <p>Date :</p>
                      </div>
                  </div>
              </div>
          </Grid>
      </PageWrapper>
  );
}
