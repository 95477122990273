import React, { useState } from "react";
import Grid from "./grid";
import { Link, useNavigate } from "react-router-dom";
import CardBuy from "./cardBuy";
import FormProduct from "./formProduct";
import ImageGallery from "react-image-gallery";

export default function blockProductBuyForm({
  productData,
  images,
  simulationData,
  activeForm,
  version,
}: any) {
  const navigate = useNavigate();

  return (
    <Grid container className="block-product">
      <Grid sm={12}>
        <div className="head"></div>
        <Grid container maxWidth="lg">
          <Grid
            sm={12}
            sx={{
              mt: {
                md: 7,
                sm: 4,
              },
              mb: {
                md: 6,
                sm: 4,
              },
            }}
          >
            <Link to=".." onClick={() => navigate(-1)} className="link-back">
              &lt; Retour
            </Link>
          </Grid>
          <Grid md={6} sm={12}>
            <div className="product">
              <div className="left-side">
                {images && images.length > 0 && <ImageGallery items={images} />}
              </div>
            </div>
          </Grid>
          <Grid
            className="right-side"
            md={6}
            sm={12}
            sx={{
              display: {
                md: "block",
              },
              px: {
                md: 4,
                sm: 0,
              },
              my: {
                md: 0,
                sm: 0,
              },
            }}
          >
            <div className="product-details">
              <CardBuy
                icon="auto"
                type="product"
                title="Informations"
                description="du véhicule"
              />
              <div className="product-details-content">
                <div className="car-content car-content-buy">
                  <div className="top">
                    <p className="name">{productData.Marque__c+" "+productData.Modele__c}</p>
                    {productData.Version__c ? <p className="description">{productData.Version__c} </p> : ""}
                  </div>
                  <div className="bottom">
                    <div className="list-specs">
                      <ul>
                        <li>
                          <span className="key">Kilomètres :</span>
                          <span className="value">
                            {productData.KmCompteur__c}km
                          </span>
                        </li>
                        <li>
                          <span className="key">Type d’essence :</span>
                          <span className="value">
                            {productData.Energie__c}
                          </span>
                        </li>
                        <li>
                          <span className="key">Boitier du véhicule :</span>
                          <span className="value">
                            {productData.TypeBoite__c}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid md={12} sm={12}>
            <FormProduct
              productData={productData}
              simulationData={simulationData}
              activeForm={activeForm}
              version={version}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
